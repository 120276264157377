import React from "react";
import { Button, Modal } from "react-bootstrap";
import { deleteStickersReq } from "../__requests/StickersRequests";

const StickerDelete = ({ show, onClose, selectedSticker, handleDeleteConfirm }) => {
  const DeleteSticker = async () => {
    try {
      const response = await deleteStickersReq(selectedSticker?._id);
      onClose();
      handleDeleteConfirm();
    } catch (err) {
      console.error("Error in deleting sticker");
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="text-danger fs-6">
            Are you sure you want to delete this sticker?
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3">
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/${selectedSticker?.image}`}
            alt={selectedSticker?.name}
            style={{
              width: "150px",
              height: "150px",
              objectFit: "contain",
              marginBottom: "15px",
            }}
          />
          <div>
            <h5>{selectedSticker?.name}</h5>
            <p className="text-muted">Value: {selectedSticker?.value}</p>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center w-100">
          <Button
            className="w-75"
            variant="danger"
            onClick={() => {
              onClose();
              DeleteSticker();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StickerDelete;
