import { USER_DATA, USER_ID } from "./constant";

export const userDataDispatcher = (userData) => {
  return {
    type: USER_DATA,
    payload: userData,
  };
};

export const userIdDispatcher = (userId) => {
  return {
    type: USER_ID,
    payload: userId,
  };
};
