import React, { useEffect, useState, useRef, useMemo } from "react";
import Globe from "react-globe.gl";
import countries from "i18n-iso-countries";
import { feature } from "topojson-client";
import {
  getUserToConnectReq,
  UsersByCountry,
} from "../__requests/UserRequests";
import { RiseLoader } from "react-spinners";

// Initialize the library with English translations
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Define a simple mapping of country coordinates
const countryCoordinates = {
  AF: "33.9391,67.7100", // Afghanistan
  AL: "41.1533,20.1683", // Albania
  DZ: "28.0339,1.6596", // Algeria
  AO: "-11.2027,17.8739", // Angola
  AR: "-38.4161,-63.6167", // Argentina
  AM: "40.0691,45.0382", // Armenia
  AU: "-25.2744,133.7751", // Australia
  AT: "47.5162,14.5501", // Austria
  AZ: "40.1431,47.5769", // Azerbaijan
  BS: "25.0343,-77.3963", // Bahamas
  BH: "26.0667,50.5577", // Bahrain
  BD: "23.6850,90.3563", // Bangladesh
  BY: "53.7098,27.9534", // Belarus
  BE: "50.8503,4.3517", // Belgium
  BZ: "17.1899,-88.4976", // Belize
  BJ: "9.3077,2.3158", // Benin
  BT: "27.5142,90.4336", // Bhutan
  BO: "-16.2902,-63.5887", // Bolivia
  BA: "43.9159,17.6791", // Bosnia and Herzegovina
  BW: "-22.3285,24.6849", // Botswana
  BR: "-14.2350,-51.9253", // Brazil
  BN: "4.5353,114.7277", // Brunei
  BG: "42.7339,25.4858", // Bulgaria
  KH: "12.5657,104.9910", // Cambodia
  CM: "7.3697,12.3547", // Cameroon
  CA: "56.1304,-106.3468", // Canada
  CF: "6.6111,20.9394", // Central African Republic
  TD: "15.4542,18.7322", // Chad
  CL: "-35.6751,-71.5430", // Chile
  CN: "35.8617,104.1954", // China
  CO: "4.5709,-74.2973", // Colombia
  CD: "-4.0383,21.7587", // Congo
  CR: "9.7489,-83.7534", // Costa Rica
  HR: "45.1000,15.2000", // Croatia
  CU: "21.5218,-77.7812", // Cuba
  CY: "35.1264,33.4299", // Cyprus
  CZ: "49.8175,15.4730", // Czech Republic
  DK: "56.2639,9.5018", // Denmark
  DJ: "11.8251,42.5903", // Djibouti
  DO: "18.7357,-70.1627", // Dominican Republic
  EC: "-1.8312,-78.1834", // Ecuador
  EG: "26.8206,30.8025", // Egypt
  SV: "13.7942,-88.8965", // El Salvador
  GQ: "1.6508,10.2679", // Equatorial Guinea
  ER: "15.1794,39.7823", // Eritrea
  EE: "58.5953,25.0136", // Estonia
  ET: "9.1450,40.4897", // Ethiopia
  FJ: "-16.5782,179.4144", // Fiji
  FI: "61.9241,25.7482", // Finland
  FR: "46.2276,2.2137", // France
  GA: "-0.8037,11.6094", // Gabon
  GM: "13.4432,-15.3101", // Gambia
  GE: "42.3154,43.3569", // Georgia
  DE: "51.1657,10.4515", // Germany
  GH: "7.9465,-1.0232", // Ghana
  GR: "39.0742,21.8243", // Greece
  GT: "15.7835,-90.2308", // Guatemala
  GN: "9.9456,-9.6966", // Guinea
  GY: "4.8604,-58.9302", // Guyana
  HT: "18.9712,-72.2852", // Haiti
  HN: "15.2000,-86.2419", // Honduras
  HK: "22.3193,114.1694", // Hong Kong
  HU: "47.1625,19.5033", // Hungary
  IS: "64.9631,-19.0208", // Iceland
  IN: "20.5937,78.9629", // India
  ID: "-0.7893,113.9213", // Indonesia
  IR: "32.4279,53.6880", // Iran
  IQ: "33.2232,43.6793", // Iraq
  IE: "53.1424,-7.6921", // Ireland
  IL: "31.0461,34.8516", // Israel
  IT: "41.8719,12.5674", // Italy
  JM: "18.1096,-77.2975", // Jamaica
  JP: "36.2048,138.2529", // Japan
  JO: "30.5852,36.2384", // Jordan
  KZ: "48.0196,66.9237", // Kazakhstan
  KE: "-0.0236,37.9062", // Kenya
  KP: "40.3399,127.5101", // North Korea
  KR: "35.9078,127.7669", // South Korea
  KW: "29.3117,47.4818", // Kuwait
  KG: "41.2044,74.7661", // Kyrgyzstan
  LA: "19.8563,102.4955", // Laos
  LV: "56.8796,24.6032", // Latvia
  LB: "33.8547,35.8623", // Lebanon
  LS: "-29.6099,28.2336", // Lesotho
  LR: "6.4281,-9.4295", // Liberia
  LY: "26.3351,17.2283", // Libya
  LT: "55.1694,23.8813", // Lithuania
  LU: "49.8153,6.1296", // Luxembourg
  MK: "41.6086,21.7453", // North Macedonia
  MG: "-18.7669,46.8691", // Madagascar
  MW: "-13.2543,34.3015", // Malawi
  MY: "4.2105,101.9758", // Malaysia
  ML: "17.5707,-3.9962", // Mali
  MR: "21.0079,-10.9408", // Mauritania
  MX: "23.6345,-102.5528", // Mexico
  MD: "47.4116,28.3699", // Moldova
  MN: "46.8625,103.8467", // Mongolia
  ME: "42.7087,19.3744", // Montenegro
  MA: "31.7917,-7.0926", // Morocco
  MZ: "-18.6657,35.5296", // Mozambique
  MM: "21.9162,95.9560", // Myanmar
  NA: "-22.9576,18.4904", // Namibia
  NP: "28.3949,84.1240", // Nepal
  NL: "52.1326,5.2913", // Netherlands
  NZ: "-40.9006,174.8860", // New Zealand
  NI: "12.8654,-85.2072", // Nicaragua
  NE: "17.6078,8.0817", // Niger
  NG: "9.0820,8.6753", // Nigeria
  NO: "60.4720,8.4689", // Norway
  OM: "21.4735,55.9754", // Oman
  PK: "30.3753,69.3451", // Pakistan
  PA: "8.5380,-80.7821", // Panama
  PG: "-6.3149,143.9555", // Papua New Guinea
  PY: "-23.4425,-58.4438", // Paraguay
  PE: "-9.1900,-75.0152", // Peru
  PH: "12.8797,121.7740", // Philippines
  PL: "51.9194,19.1451", // Poland
  PT: "39.3999,-8.2245", // Portugal
  QA: "25.3548,51.1839", // Qatar
  RO: "45.9432,24.9668", // Romania
  RU: "61.5240,105.3188", // Russia
  RW: "-1.9403,29.8739", // Rwanda
  SA: "23.8859,45.0792", // Saudi Arabia
  SN: "14.4974,-14.4524", // Senegal
  RS: "44.0165,21.0059", // Serbia
  SL: "8.4606,-11.7799", // Sierra Leone
  SG: "1.3521,103.8198", // Singapore
  SK: "48.6690,19.6990", // Slovakia
  SI: "46.1512,14.9955", // Slovenia
  SO: "5.1521,46.1996", // Somalia
  ZA: "-30.5595,22.9375", // South Africa
  SS: "6.8770,31.3070", // South Sudan
  ES: "40.4637,-3.7492", // Spain
  LK: "7.8731,80.7718", // Sri Lanka
  SD: "12.8628,30.2176", // Sudan
  SE: "60.1282,18.6435", // Sweden
  CH: "46.8182,8.2275", // Switzerland
  SY: "34.8021,38.9968", // Syria
  TW: "23.6978,120.9605", // Taiwan
  TJ: "38.8610,71.2761", // Tajikistan
  TZ: "-6.3690,34.8888", // Tanzania
  TH: "15.8700,100.9925", // Thailand
  TL: "-8.8742,125.7275", // Timor-Leste
  TG: "8.6195,0.8248", // Togo
  TN: "33.8869,9.5375", // Tunisia
  TR: "38.9637,35.2433", // Turkey
  TM: "38.9697,59.5563", // Turkmenistan
  UG: "1.3733,32.2903", // Uganda
  UA: "48.3794,31.1656", // Ukraine
  AE: "23.4241,53.8478", // United Arab Emirates
  GB: "55.3781,-3.4360", // United Kingdom
  US: "37.0902,-95.7129", // United States
  UY: "-32.5228,-55.7658", // Uruguay
  UZ: "41.3775,64.5853", // Uzbekistan
  VE: "6.4238,-66.5897", // Venezuela
  VN: "14.0583,108.2772", // Vietnam
  YE: "15.5527,48.5164", // Yemen
  ZM: "-13.1339,27.8493", // Zambia
  ZW: "-19.0154,29.1549", // Zimbabwe
};

// Fetch world geography data
const fetchCountriesData = async () => {
  const response = await fetch(
    "https://unpkg.com/world-atlas/countries-110m.json"
  );
  const data = await response.json();
  return feature(data, data.objects.countries);
};

const CountryFlag = ({ countryCode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  if (!countryCode || countryCode === "unknown") {
    return (
      <div className="flag-container position-relative">
        <div className="w-40-px h-40-px rounded-circle flex-shrink-0 unknown-flag d-flex align-items-center justify-content-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
        </div>
        <style jsx>{`
          .unknown-flag {
            background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
            color: #8898aa;
            border: 2px solid #e4e8eb;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
            transition: all 0.2s ease-in-out;
          }

          .flag-container:hover .unknown-flag {
            transform: scale(1.1);
            color: #487fff;
            border-color: #487fff;
            box-shadow: 0 4px 8px rgba(72, 127, 255, 0.2);
          }
        `}</style>
      </div>
    );
  }

  return (
    <div className="flag-container position-relative">
      {isLoading && (
        <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-sm text-primary" />
        </div>
      )}
      <img
        src={`https://flagcdn.com/w320/${countryCode.toLowerCase()}.png`}
        srcSet={`
          https://flagcdn.com/w320/${countryCode.toLowerCase()}.png 2x,
          https://flagcdn.com/w640/${countryCode.toLowerCase()}.png 3x
        `}
        alt=""
        onLoad={() => setIsLoading(false)}
        onError={(e) => {
          setIsLoading(false);
          setHasError(true);
          e.target.onerror = null;
          e.target.src =
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E";
        }}
        className={`w-40-px h-40-px rounded-circle flex-shrink-0 flag-image ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
        style={{
          objectFit: "cover",
          imageRendering: "-webkit-optimize-contrast",
          transition: "all 0.2s ease-in-out",
          border: "2px solid transparent",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      />
      <style jsx>{`
        .flag-container {
          position: relative;
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
        }

        .flag-container:hover {
          transform: scale(1.1);
        }

        .flag-container:hover .flag-image {
          border-color: #487fff;
          box-shadow: 0 4px 8px rgba(72, 127, 255, 0.2);
        }

        .flag-image {
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          transform: translateZ(0);
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
        }

        .opacity-0 {
          opacity: 0;
        }

        .opacity-100 {
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

// Add these helper functions before the TopCountries component
const getPointSize = (count) => {
  // Scale the point size based on user count
  // Minimum size of 0.5, maximum size of 2
  return Math.min(Math.max(0.5, count / 20), 2);
};

const getPointColor = (count, maxUsers) => {
  // Color gradient from blue to red based on user count
  const intensity = Math.min(count / maxUsers, 1);
  return `rgba(${Math.round(intensity * 255)}, ${Math.round(
    100 + (1 - intensity) * 155
  )}, ${Math.round((1 - intensity) * 255)}, 0.8)`;
};

// Add these styles at the top of your file
const starryBackgroundStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "radial-gradient(circle at center, #1a1f35 0%, #0a0f1f 100%)",
  overflow: "hidden",
};

const twinkleAnimation = `
  @keyframes twinkle {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 0.8; }
  }

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-2px); }
    100% { transform: translateY(0px); }
  }

  .star {
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #ffffff;
  }

  .star-small {
    width: 1px;
    height: 1px;
    opacity: 0.5;
  }

  .star-medium {
    width: 2px;
    height: 2px;
    opacity: 0.7;
  }

  .star-large {
    width: 3px;
    height: 3px;
    opacity: 0.9;
    box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.3);
  }

  .twinkle-1 { animation: twinkle 3s infinite ease-in-out; }
  .twinkle-2 { animation: twinkle 4s infinite ease-in-out; }
  .twinkle-3 { animation: twinkle 5s infinite ease-in-out; }

  .nebula {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 30% 50%, rgba(63, 94, 251, 0.05) 0%, rgba(63, 94, 251, 0) 50%),
                radial-gradient(circle at 70% 50%, rgba(252, 70, 107, 0.05) 0%, rgba(252, 70, 107, 0) 50%);
    opacity: 0.6;
    filter: blur(30px);
  }
`;

const TopCountries = () => {
  const [countryData, setCountryData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const globeEl = useRef();
  const [countriesData, setCountriesData] = useState({ features: [] });

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        setIsLoading(true);
        const response = await UsersByCountry();

        // Process the country statistics
        const countryStats = {};

        response.meta.summary.forEach((countryInfo) => {
          const inputCountry = countryInfo.country?.trim() || "Unknown";
          let countryCode = null;
          let countryName = inputCountry;

          if (inputCountry !== "Unknown") {
            // Try different methods to get the country code
            countryCode =
              // Try direct match if it's already a 2-letter code
              (inputCountry.length === 2 &&
              countries.isValid(inputCountry.toUpperCase())
                ? inputCountry.toUpperCase()
                : null) ||
              // Try to get alpha2 code from country name
              countries.getAlpha2Code(inputCountry, "en") ||
              // Try to convert alpha3 to alpha2
              (inputCountry.length === 3
                ? countries.alpha3ToAlpha2(inputCountry.toUpperCase())
                : null);

            if (countryCode) {
              // Get standardized country name from the code
              countryName =
                countries.getName(countryCode, "en") || inputCountry;
            }
          }

          countryStats[countryName] = {
            count: countryInfo.count,
            countryCode: countryCode || "Unknown",
            code: countryCode ? countryCode.toLowerCase() : "unknown",
            percentage: countryInfo.percentage,
          };
        });

        setCountryData(countryStats);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllUsers();
  }, []);

  useEffect(() => {
    // Load country data when component mounts
    fetchCountriesData().then((countries) => {
      setCountriesData(countries);
    });
  }, []);

  useEffect(() => {
    if (globeEl.current) {
      const controls = globeEl.current.controls();
      if (controls) {
        controls.autoRotate = true;
        controls.autoRotateSpeed = 2.0; // Increased to 2.0 for faster rotation
        controls.enableDamping = true;
        controls.dampingFactor = 0.1;
      }
      globeEl.current.pointOfView({ altitude: 2.5 });

      // Start an animation loop to ensure continuous rotation
      let frameId;
      const animate = () => {
        if (globeEl.current && globeEl.current.controls()) {
          globeEl.current.controls().update();
        }
        requestAnimationFrame(animate);
      };
      animate();
    }
  }, []);

  // Add resize handler
  useEffect(() => {
    const handleResize = () => {
      if (globeEl.current) {
        const altitude = window.innerWidth <= 768 ? 2.8 : 2.5;
        globeEl.current.pointOfView({
          altitude,
          lat: 0,
          lng: 0,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initial setup for globe controls
  useEffect(() => {
    if (globeEl.current) {
      const controls = globeEl.current.controls();
      if (controls) {
        controls.autoRotate = true;
        controls.autoRotateSpeed = 0.5;
        controls.enableDamping = true;
        controls.dampingFactor = 0.1;
        controls.enableZoom = true;

        // Center the initial view
        globeEl.current.pointOfView({
          altitude: window.innerWidth <= 768 ? 2.8 : 2.5,
          lat: 0,
          lng: 0,
        });
      }
    }
  }, []);

  // Function to generate random star positions
  const generateStars = () => {
    const stars = [];
    const starCount = 100;

    for (let i = 0; i < starCount; i++) {
      const size =
        Math.random() < 0.6
          ? "small"
          : Math.random() < 0.8
          ? "medium"
          : "large";
      const twinkleClass = `twinkle-${Math.floor(Math.random() * 3 + 1)}`;

      stars.push({
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        size,
        twinkleClass,
        delay: `${Math.random() * 5}s`,
      });
    }
    return stars;
  };

  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.textContent = twinkleAnimation;
    document.head.appendChild(styleTag);
    return () => styleTag.remove();
  }, []);

  const stars = useMemo(() => generateStars(), []);

  // Add this new function to handle flag clicks
  const handleFlagClick = (countryCode) => {
    if (!countryCode || countryCode === "unknown" || !globeEl.current) return;

    const coords = countryCoordinates[countryCode.toUpperCase()];
    if (!coords) return;

    const [lat, lng] = coords.split(",").map(Number);

    // Animate to the new position and zoom in
    globeEl.current.pointOfView(
      {
        lat,
        lng,
        altitude: 1.5, // Zoomed in view
      },
      1000
    );

    // Temporarily disable auto-rotation
    const controls = globeEl.current.controls();
    if (controls) {
      controls.autoRotate = false;

      // After 4 seconds, zoom out and resume rotation
      setTimeout(() => {
        // First zoom out
        globeEl.current.pointOfView(
          {
            lat: 0,
            lng: 0,
            altitude: window.innerWidth <= 768 ? 2.8 : 2.5, // Return to default altitude
          },
          1000
        );

        // Then resume rotation
        setTimeout(() => {
          controls.autoRotate = true;
        }, 1000); // Start rotation after zoom out animation completes
      }, 4000);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ marginTop: "40px" }}
        >
          <RiseLoader color="#487FFF" />
        </div>
      ) : (
        <div className="col-xl-12" style={{ marginTop: "20px" }}>
          <div className="card h-100">
            <div className="card-body">
              <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                <h6 className="mb-2 fw-bold text-lg mb-0">
                  User Distribution by Country
                </h6>
              </div>
              <div className="row gy-4">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="border p-16 pe-0 radius-8"
                    style={{
                      height: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <div className="scroll-sm pe-16">
                      {Object.entries(countryData)
                        .sort(([_, a], [__, b]) => b.count - a.count)
                        .map(([countryName, data]) => (
                          <div
                            key={countryName}
                            className="d-flex align-items-center justify-content-between gap-3 mb-12 pb-2"
                          >
                            <div className="d-flex align-items-center w-100">
                              <div
                                className="me-12"
                                onClick={() => handleFlagClick(data.code)}
                                style={{ cursor: "pointer" }}
                              >
                                <CountryFlag countryCode={data.code} />
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="text-sm mb-0">{countryName}</h6>
                                <span className="text-xs text-secondary-light fw-medium">
                                  {data.count} Users
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 w-100">
                              <div className="w-100 max-w-66 ms-auto">
                                <div className="progress progress-sm rounded-pill">
                                  <div
                                    className="progress-bar bg-primary-600 rounded-pill"
                                    style={{ width: data.percentage }}
                                  />
                                </div>
                              </div>
                              <span className="text-secondary-light font-xs fw-semibold">
                                {data.percentage}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div
                    className="border radius-8 overflow-hidden d-flex justify-content-center align-items-center"
                    style={{
                      position: "relative",
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    {/* Background container */}
                    <div style={starryBackgroundStyle}>
                      {/* Nebula effect */}
                      <div className="nebula" />

                      {/* Stars */}
                      {stars.map((star, index) => (
                        <div
                          key={index}
                          className={`star star-${star.size} ${star.twinkleClass}`}
                          style={{
                            left: star.left,
                            top: star.top,
                            animationDelay: star.delay,
                          }}
                        />
                      ))}
                    </div>

                    {/* Globe Container */}
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                      }}
                    >
                      <Globe
                        ref={globeEl}
                        width={Math.min(
                          window.innerWidth <= 768
                            ? window.innerWidth - 40
                            : 500,
                          window.innerWidth - 40
                        )}
                        height={Math.min(400, window.innerHeight - 100)}
                        globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                        bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                        backgroundColor="rgba(0,0,0,0)"
                        atmosphereColor="rgba(63, 94, 251, 0.3)"
                        atmosphereAltitude={0.25}
                        polygonsData={countriesData.features}
                        polygonStrokeColor={() => "rgba(255, 255, 255, 0.8)"}
                        polygonSideColor={() => "rgba(63, 77, 126, 0.15)"}
                        polygonCapColor={() => "rgba(200, 200, 200, 0.15)"}
                        polygonAltitude={0.04}
                        polygonStrokeWidth={1.5}
                        pointsData={Object.entries(countryData)
                          .filter(
                            ([_, data]) =>
                              data.countryCode !== "Unknown" && data.count > 0
                          )
                          .map(([countryName, data]) => {
                            const coords = countryCoordinates[data.countryCode];
                            if (!coords) return null;
                            const [lat, lng] = coords.split(",").map(Number);
                            const maxUsers = Math.max(
                              ...Object.values(countryData).map((d) => d.count)
                            );
                            return {
                              lat,
                              lng,
                              size: getPointSize(data.count),
                              color: getPointColor(data.count, maxUsers),
                              countryName,
                              countryCode: data.code,
                              userCount: data.count,
                            };
                          })
                          .filter(Boolean)}
                        pointAltitude={0.15}
                        pointRadius="size"
                        pointColor="color"
                        pointResolution={12}
                        pointsMerge={true}
                        pointLabel={(point) => `
                          <div style="
                            background: white;
                            padding: 16px;
                            border-radius: 12px;
                            box-shadow: 0 8px 24px rgba(0,0,0,0.15);
                            border: 1px solid rgba(0,0,0,0.1);
                            font-family: system-ui, -apple-system, sans-serif;
                            transform: scale(0.9);
                            transition: transform 0.2s ease-in-out;
                          ">
                            <div style="display: flex; align-items: center; gap: 12px;">
                              ${
                                point.countryCode === "unknown"
                                  ? `
                                <div style="
                                  width: 36px;
                                  height: 36px;
                                  border-radius: 6px;
                                  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                                  border: 2px solid #fff;
                                ">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#8898aa" stroke-width="2">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="12"></line>
                                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                  </svg>
                                </div>
                              `
                                  : `
                                <img 
                                  src="https://flagcdn.com/w320/${point.countryCode.toLowerCase()}.png"
                                  srcset="
                                    https://flagcdn.com/w320/${point.countryCode.toLowerCase()}.png 2x,
                                    https://flagcdn.com/w640/${point.countryCode.toLowerCase()}.png 3x
                                  "
                                  style="
                                    width: 36px; 
                                    height: 36px; 
                                    border-radius: 6px; 
                                    image-rendering: -webkit-optimize-contrast;
                                    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                                    border: 2px solid #fff;
                                  "
                                  alt="${point.countryName}"
                                />
                              `
                              }
                              <strong style="
                                font-size: 18px;
                                color: #2d3748;
                              ">${point.countryName}</strong>
                            </div>
                            <div style="
                              margin-top: 12px;
                              padding-top: 8px;
                              border-top: 1px solid rgba(0,0,0,0.06);
                              color: #487FFF;
                              font-weight: 600;
                              font-size: 16px;
                              display: flex;
                              align-items: center;
                              gap: 6px;
                            ">
                              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="9" cy="7" r="4"></circle>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                              </svg>
                              ${point.userCount.toLocaleString()} Users
                            </div>
                          </div>
                        `}
                        onGlobeReady={() => {
                          if (globeEl.current) {
                            const controls = globeEl.current.controls();
                            if (controls) {
                              controls.autoRotate = true;
                              controls.autoRotateSpeed = 0.5;
                              controls.enableZoom = true;

                              // Adjust camera position based on screen size
                              const altitude =
                                window.innerWidth <= 768 ? 2.8 : 2.5;
                              globeEl.current.pointOfView({
                                altitude,
                                lat: 0,
                                lng: 0,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopCountries;
