import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { userDataDispatcher } from "../redux/action";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    backend: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Clear errors when user types
    setErrors({
      ...errors,
      [e.target.name]: "",
      backend: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    const newErrors = { email: "", password: "", backend: "" };

    // Validation
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    if (!isValid) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/fruit/user/login`,
        formData
      );

      dispatch(userDataDispatcher(response?.data?.user));

      const { token, user } = response.data;

      if (token) {
        if (user?.role === "admin") {
          localStorage.setItem("token", token);
          localStorage.setItem("role", user.role);
          sessionStorage.setItem("token", token);

          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          navigate("/dashboard");
          toast.success("Admin login successful!", {
            position: "top-center",
          });
        } else {
          // Clear any stored tokens
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          sessionStorage.removeItem("token");

          setErrors((prev) => ({
            ...prev,
            backend:
              "Access denied. Only administrators can login to the dashboard.",
          }));

          toast.error("Access denied. Only administrators can login.", {
            position: "top-center",
          });
        }
      }
    } catch (error) {
      if (error?.response?.data?.error === "Invalid email or password") {
        setErrors((prev) => ({
          ...prev,
          backend: "Invalid email or password",
        }));
      } else {
        toast.error(
          "Your Account is not Verified. Please verify your account!",
          {
            position: "top-center",
          }
        );
      }
    }
  };

  return (
    <div
      className="login-container d-flex align-items-center justify-content-center min-vh-100"
      style={{
        backgroundImage: "url(/Banner_final_image.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        className="form-wrapper"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "15px",
          padding: "40px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px",
          margin: "20px",
        }}
      >
        <h5 className="text-center mb-4" style={{ color: "#333" }}>
          FruityChat Dashboard
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control form-control-lg"
              placeholder="Email"
              style={{
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            />
            {errors.email && (
              <div className="text-danger small mt-1">{errors?.email}</div>
            )}
          </div>

          <div className="mb-4 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="form-control form-control-lg"
              placeholder="Password"
              style={{
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "12px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
            {errors.password && (
              <div className="text-danger small mt-1">{errors.password}</div>
            )}
          </div>

          {errors.backend && (
            <div className="alert alert-danger">{errors.backend}</div>
          )}

          <button
            type="submit"
            className="btn btn-primary w-100 py-3"
            style={{
              backgroundColor: "#007bff",
              border: "none",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "500",
              transition: "all 0.3s ease",
            }}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
