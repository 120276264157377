import React, { useEffect, useState, useSyncExternalStore } from "react";

import { getPackagesReq } from "../__requests/PackagesRequests";
import { useSelector } from "react-redux";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { customList } from "country-codes-list";
import { Modal, Button } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import "./Packages.css";
import PackageDetailModal from "./PackageDetailModal";
import CreatePackages from "./CreatePackages";
 
countries.registerLocale(enLocale);

const PackagesList = () => {
  const [showCreatePackages, setShowCreatePackages] = useState(false);
  const handleClose = () => setShowCreatePackages(false);
  const handleShow = () => setShowCreatePackages(true);

  const userData = useSelector((state) => state.userDataReducer);

  const [packages, setPackages] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const countryNameList = customList("countryCode", "{countryNameEn}");
  const countryOptions = [
    { value: "all", label: "All Countries" },
    ...Object.entries(countryNameList).map(([code, name]) => ({
      value: code,
      label: name,
    })),
  ];

  const getPackages = async () => {
    try {
      setLoading(true);
      const response = await getPackagesReq(userData?._id);
      setPackages(response?.packages || []);
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoading(false);
    }
  };

  // Updated function to handle package updates in real-time
  const handlePackageUpdate = (updatedPackage) => {
    // Update the packages list
    setPackages(prevPackages => 
      prevPackages.map(pkg => 
        pkg._id === updatedPackage._id ? updatedPackage : pkg
      )
    );
    // Update the selected package
    setSelectedPackage(updatedPackage);
  };

  useEffect(() => {
    getPackages();
  }, []);

  // Group packages by country
  const groupedPackages = packages.reduce((acc, pkg) => {
    const countryName = countries.getName(pkg.country, "en") || pkg.country;
    if (!acc[countryName]) {
      acc[countryName] = [];
    }
    acc[countryName].push(pkg);
    return acc;
  }, {});

  // Get list of available countries from packages
  const availableCountries = [
    ...new Set(
      packages.map((pkg) => {
        return countries.getName(pkg.country, "en") || pkg.country;
      })
    ),
  ];

  // Filter packages based on selected country
  const filteredGroupedPackages =
    !selectedCountry || selectedCountry.value === "all"
      ? groupedPackages
      : { [selectedCountry.label]: groupedPackages[selectedCountry.label] };

  const getEnabledFeatures = (features) => {
    return Object.entries(features)
      .filter(([_, value]) => value.isEnabled)
      .map(([key]) => key);
  };

  const handlePackageClick = (pkg) => {
    setSelectedPackage(pkg);
    setShowPackageModal(true);
  };

  const handleClosePackageModal = () => {
    setShowPackageModal(false);
    setSelectedPackage(null);
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title mb-0">List of Packages</h5>

            <div className="d-flex gap-2 align-items-center">
              <div style={{ minWidth: "200px" }}>
                <Select
                  isClearable
                  placeholder="Select Country"
                  value={selectedCountry}
                  onChange={(value) => setSelectedCountry(value)}
                  options={countryOptions}
                  className="w-100"
                />
              </div>
              <button className="btn btn-primary" onClick={handleShow}>
                Create New Package
              </button>
            </div>
          </div>
          <div className="card-body">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center py-5">
                <ScaleLoader color="#0d6efd" />
              </div>
            ) : Object.keys(filteredGroupedPackages).length === 0 ? (
              <div className="text-center py-5">
                <h4 className="text-muted">No Packages Found</h4>
              </div>
            ) : (
              <div className="d-flex flex-column">
                {Object.entries(filteredGroupedPackages).map(
                  ([country, countryPackages]) => (
                    <div key={country} className="w-100">
                      <div className="card">
                        <div className="card-header">
                          <h5>{country}</h5>
                        </div>
                        <div className="card-body overflow-auto d-flex align-items-center justify-content-evenly">
                          {countryPackages.map((pkg) => (
                            <div
                              key={pkg._id}
                              className="package-card"
                              onClick={() => handlePackageClick(pkg)}
                            >
                              <h6 className="text-primary">{pkg.type}</h6>
                              <div className="d-flex justify-content-between mb-2">
                                <span>Price: ${pkg.price}</span>
                                <span>Validity: {pkg.validity} days</span>
                              </div>
                              <div>
                                <h6>Enabled Features:</h6>
                                <ul className="list-unstyled">
                                  {getEnabledFeatures(pkg.features).map(
                                    (feature) => (
                                      <li
                                        key={feature}
                                        className="mb-1 d-flex align-items-center"
                                      >
                                        <TiTick />
                                        {feature
                                          .replace(/([A-Z])/g, " $1")
                                          .trim()}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CreatePackages 
        show={showCreatePackages} 
        onClose={handleClose} 
        isEditing={false}
      />
      <PackageDetailModal 
        showPackageModal={showPackageModal}
        handleClosePackageModal={handleClosePackageModal}
        selectedPackage={selectedPackage}
        onPackageUpdate={handlePackageUpdate}
        refreshPackages={getPackages}
      />
    </>
  );
};

export default PackagesList;
