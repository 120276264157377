import { USER_DATA, USER_ID } from "./constant";

export const userDataReducer = (state = [], action) => {
  if (action.type === USER_DATA) {
    return action.payload;
  } else {
    return state;
  }
};

export const userIdReducer = (state = [], action) => {
  if (action.type === USER_ID) {
    return action.payload;
  } else {
    return state;
  }
};
