import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import JobsList from '../components/jobs/JobsList'

const Jobs = () => {
  return (
    <MasterLayout>
    <JobsList />
  </MasterLayout>
  )
}

export default Jobs
