import React, { useEffect, useState } from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  getCompanyDetailsReq,
  getsingleCompanyReq,
} from "../__requests/CompanyRequests";
import { MdModeEditOutline } from "react-icons/md";
import { format } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";

const CompanyProfile = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("about");
  const [userProfile, setUserProfile] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [showFullAbout, setShowFullAbout] = useState(false);
  console.log("companyProfile", companyProfile);

  const handleCompanyProfile = async () => {
    try {
      const response = await getsingleCompanyReq(id);
      setCompanyProfile(response?.pages);
    } catch (error) {
      console.log("Error in fetching company profile", error.message);
    }
  };

  useEffect(() => {
    handleCompanyProfile();
  }, []);

  const renderAboutText = () => {
    if (!companyProfile?.about) return "";

    if (companyProfile.about.length <= 70 || showFullAbout) {
      return (
        <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {companyProfile.about}
        </span>
      );
    }
    return (
      <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {companyProfile.about.substring(0, 70) + "..."}
      </span>
    );
  };

  const [companyDetails, setCompanyDetails] = useState("");
  const handleCompanyDetails = async () => {
    try {
      const response = await getCompanyDetailsReq(id);
      console.log("getCompanyDetailsReq", response);
      setCompanyDetails(response);
    } catch (error) {
      console.log("Error in fetching company details", error.message);
    }
  };

  useEffect(() => {
    handleCompanyDetails();
  }, [id]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(new Date(dateString), "MMM dd yyyy");
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString;
    }
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    try {
      const phoneWithCode = phone.startsWith("+") ? phone : `+${phone}`;
      const phoneNumber = parsePhoneNumber(phoneWithCode);
      return phoneNumber.formatInternational();
    } catch (error) {
      console.error("Error formatting phone number:", error);
      return phone;
    }
  };

  return (
    <MasterLayout>
      <div className="col-lg-12">
        <div className="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
          <img
            src={
              companyProfile?.cover_photo
                ? `${process.env.REACT_APP_IMAGE_URL}/${companyProfile?.cover_photo}`
                : "/empty_gray_image.jpg"
            }
            alt=""
            className="w-100 object-fit-cover"
            style={{ height: "250px" }}
          />
          <div className="pb-24 ms-16 mb-24 me-16  mt--100">
            <div className="text-center border border-top-0 border-start-0 border-end-0">
              <img
                src={
                  companyProfile?.profile_photo
                    ? `${process.env.REACT_APP_IMAGE_URL}/${companyProfile?.profile_photo}`
                    : "/Default_user_Image.png"
                }
                alt=""
                className="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover"
              />
              <h6 className="mb-0 mt-16">{companyProfile?.name}</h6>
              <span className="text-secondary-light mb-16">
                {companyProfile?.category}
              </span>
              <div className="d-flex justify-content-center gap-2 mt-3 mb-3">
                <button
                  className={`btn ${
                    activeTab === "about"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setActiveTab("about")}
                >
                  About Company
                </button>
                <button
                  className={`btn ${
                    activeTab === "posts"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setActiveTab("posts")}
                >
                  Company Posts
                </button>
              </div>
            </div>
            {activeTab === "about" ? (
              <>
                <div className="mt-24">
                  <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                    <span className="text-xl fw-bold fs-4 text-primary">
                      About Company
                    </span>
                  </div>
                  <ul>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Address
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.address}
                      </span>
                    </li>
                    {companyProfile?.openingTime && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Timings
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {companyProfile?.openingTime} to{" "}
                          {companyProfile?.closingTime}
                        </span>
                      </li>
                    )}
                    {companyProfile?.contact_number && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Phone
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {formatPhoneNumber(companyProfile?.contact_number)}
                        </span>
                      </li>
                    )}
                    {companyProfile?.email && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Company Email
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {companyProfile?.email}
                        </span>
                      </li>
                    )}
                    {companyProfile?.zip_code && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Zip Code
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {companyProfile?.zip_code}
                        </span>
                      </li>
                    )}
                    {companyProfile?.company_size && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Employees
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {companyProfile?.company_size}
                        </span>
                      </li>
                    )}
                    <li className="d-flex align-items-start gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company about
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {renderAboutText()}
                        {companyProfile?.about?.length > 70 && (
                          <button
                            onClick={() => setShowFullAbout(!showFullAbout)}
                            className="btn btn-link p-0 ms-1 text-primary"
                          >
                            {showFullAbout ? "Show Less" : "Show More"}
                          </button>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-24">
                  <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                    <span className="text-xl fw-bold fs-4 text-primary">
                      About Company Owner
                    </span>
                    <Link to={`/user-profile/${companyProfile?.userData?._id}`}>
                      <button className="btn btn-primary">
                        View Owner's profile
                      </button>
                    </Link>
                  </div>
                  <ul>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Owner Name
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.userData?.name}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Owner Email
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.userData?.email}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-24">
                  <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                    <span className="text-xl fw-bold fs-4 text-primary">
                      Company Info
                    </span>
                  </div>
                  <ul>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company created at
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {formatDate(companyProfile?.createdAt)}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Subscription Type
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.packageType}
                      </span>
                    </li>
                    {companyProfile?.trialStartTime && (
                      <li className="d-flex align-items-center gap-1 mb-12">
                        <span className="w-30 text-md fw-semibold text-primary-light">
                          Trial Started at
                        </span>
                        <span className="w-70 text-secondary-light fw-medium">
                          : {companyProfile?.trialStartTime}
                        </span>
                      </li>
                    )}
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Followers
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.followers?.length}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Followings
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.following?.length}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Viewes
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyProfile?.totalCompanyProfileViews}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Products
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.ProductsCount}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Jobs
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.companyJobsCount}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Posts
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.postCount}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Reactions
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.reactionsCount}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Comments
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.commentsCount}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Company Replies
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {companyDetails?.replyCount}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

export default CompanyProfile;
