import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import countries from "i18n-iso-countries";
import CreatePackages from "./CreatePackages";

const PackageDetailModal = ({
  showPackageModal,
  handleClosePackageModal,
  selectedPackage,
  onPackageUpdate,
}) => {
  const [showCreatePackages, setShowCreatePackages] = useState(false);

  const handleEdit = () => {
    if (selectedPackage) {
      setShowCreatePackages(true);
    }
  };

  const handlePackageUpdate = (updatedPackage) => {
    onPackageUpdate(updatedPackage);
    setShowCreatePackages(false);
  };

  return (
    <>
      <Modal
        show={showPackageModal}
        onHide={handleClosePackageModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedPackage?.type} Package -{" "}
            {countries.getName(selectedPackage?.country, "en")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPackage && (
            <div className="p-3">
              <div className="d-flex justify-content-between mb-4">
                <h5 className="text-primary">
                  Price: ${selectedPackage.price}
                </h5>
                <h5>Validity: {selectedPackage.validity} days</h5>
              </div>

              <div className="mb-4">
                <h5 className="mb-3">Package Features:</h5>
                <div className="row">
                  {Object.entries(selectedPackage.features).map(
                    ([feature, details]) => (
                      <div key={feature} className="col-md-6 mb-3">
                        <div
                          className={`d-flex align-items-start ${
                            !details.isEnabled ? "text-muted" : ""
                          }`}
                        >
                          <div className="me-2">
                            {details.isEnabled ? (
                              <TiTick className="text-success" size={20} />
                            ) : (
                              <span>✕</span>
                            )}
                          </div>
                          <div>
                            <div>
                              {feature.replace(/([A-Z])/g, " $1").trim()}
                            </div>
                            {details.isEnabled && details.description && (
                              <small className="text-muted">
                                {details.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="warning"
            onClick={handleEdit}
            disabled={!selectedPackage}
          >
            Edit Package
          </Button>
          <Button variant="primary" onClick={handleClosePackageModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <CreatePackages
        show={showCreatePackages}
        onClose={() => setShowCreatePackages(false)}
        packageData={selectedPackage}
        isEditing={true}
        onSuccess={handlePackageUpdate}
      />
    </>
  );
};

export default PackageDetailModal;
