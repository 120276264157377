import axiosInstance from "../../api/AxiosInstance";

export const getCommentsReq = async (postId, page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/post/comments/${postId}?page=${page}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const getCommentRepliesReq = async (commentId, page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/post/comments/comments/${commentId}/replies?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};
