import axiosInstance from "../../api/AxiosInstance";

export const createPackageReq = async (adminId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/package/admin/${adminId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const getPackagesReq = async (adminId) => {
  try {
    const response = await axiosInstance.get(`/package/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const updatePackageReq = async (adminId, packageId, formData) => {
  try {
    const response = await axiosInstance.put(
      `/package/admin/${adminId}/${packageId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};
