import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import {
  createPackageReq,
  updatePackageReq,
} from "../__requests/PackagesRequests";
import { useSelector } from "react-redux";
import { customList } from "country-codes-list";
import Select from "react-select";
import { toast } from "react-hot-toast";

const countryNameList = customList("countryCode", "{countryNameEn}");

// Add All Countries option
const ALL_COUNTRIES_OPTION = { value: "ALL", label: "All Countries" };

const CreatePackages = ({
  show,
  onClose,
  packageData,
  isEditing = false,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.userDataReducer);
  const [formData, setFormData] = useState({
    type: "Free",
    countries: [],
    price: 0,
    validity: 0,
    features: {
      createCompany: { isEnabled: false, description: "" },
      productListing: { isEnabled: false, description: "" },
      discountedProducts: { isEnabled: false, description: "" },
      companyViews: { isEnabled: false, description: "" },
      companyContactHide: { isEnabled: false, description: "" },
      directContactWhatsApp: { isEnabled: false, description: "" },
      directContact: { isEnabled: false, description: "" },
      companyPost: { isEnabled: false, description: "" },
      productViews: { isEnabled: false, description: "" },
      jobPosting: { isEnabled: false, description: "" },
    },
  });

  useEffect(() => {
    if (isEditing && packageData && Object.keys(packageData).length > 0) {
      setFormData({
        type: packageData.type || "Free",
        countries: packageData.country ? [packageData.country] : [],
        price: packageData.price || 0,
        validity: packageData.validity || 0,
        features: packageData.features || {
          createCompany: { isEnabled: false, description: "" },
          productListing: { isEnabled: false, description: "" },
          discountedProducts: { isEnabled: false, description: "" },
          companyViews: { isEnabled: false, description: "" },
          companyContactHide: { isEnabled: false, description: "" },
          directContactWhatsApp: { isEnabled: false, description: "" },
          directContact: { isEnabled: false, description: "" },
          companyPost: { isEnabled: false, description: "" },
          productViews: { isEnabled: false, description: "" },
          jobPosting: { isEnabled: false, description: "" },
        },
      });
    }
  }, [isEditing, packageData]);

  const countryOptions = [
    ALL_COUNTRIES_OPTION,
    ...Object.entries(countryNameList).map(([code, name]) => ({
      value: code,
      label: name,
    })),
  ];

  const packageTypeOptions = [
    { value: "Free", label: "Free" },
    { value: "Trial", label: "Trial" },
    { value: "Premium", label: "Premium" },
  ];

  const handleInputChange = (input) => {
    if (input.target) {
      const { name, value } = input.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      const { name, value } = input;

      // Handle special case for countries selection
      if (name === "countries") {
        const selectedValues = value?.value || [];

        // If "All Countries" is selected, use all country codes
        if (selectedValues.includes("ALL")) {
          const allCountryCodes = Object.keys(countryNameList);
          setFormData((prev) => ({
            ...prev,
            countries: allCountryCodes,
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            countries: selectedValues,
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value?.value || "",
        }));
      }
    }
  };

  const handleFeatureChange = (feature, field, value) => {
    setFormData((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        [feature]: {
          ...prev.features[feature],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    const savePackage = async () => {
      if (isEditing && packageData?._id) {
        const response = await updatePackageReq(
          userData?._id,
          packageData._id,
          {
            ...formData,
            country: formData.countries[0],
          }
        );

        if (response.success) {
          onSuccess({
            ...formData,
            country: formData.countries[0],
            _id: packageData._id,
          });
          onClose();
        }
        return response;
      } else {
        const createPromises = formData.countries.map((country) =>
          createPackageReq(userData?._id, {
            ...formData,
            country,
          })
        );

        const responses = await Promise.all(createPromises);
        if (responses.every((response) => response.success)) {
          onClose();
        }
        return responses;
      }
    };

    try {
      setIsLoading(true);
      await toast.promise(savePackage(), {
        loading: isEditing ? "Updating package..." : "Creating package...",
        success: isEditing
          ? "Package updated successfully!"
          : "Package created successfully!",
        error: isEditing
          ? "Failed to update package"
          : "Failed to create package",
      });
    } catch (error) {
      console.error("Error submitting package:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? "Edit Package" : "Create Package"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Package Type</Form.Label>
                <Select
                  name="type"
                  value={packageTypeOptions.find(
                    (option) => option.value === formData.type
                  )}
                  onChange={(value) =>
                    handleInputChange({ name: "type", value })
                  }
                  options={packageTypeOptions}
                  className="w-100"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Countries</Form.Label>
                <Select
                  name="countries"
                  isMulti
                  isClearable
                  placeholder="Select Countries"
                  value={
                    formData.countries.length ===
                    Object.keys(countryNameList).length
                      ? [ALL_COUNTRIES_OPTION]
                      : countryOptions.filter(
                          (option) =>
                            formData.countries.includes(option.value) &&
                            option.value !== "ALL"
                        )
                  }
                  onChange={(values) =>
                    handleInputChange({
                      name: "countries",
                      value: { value: values.map((v) => v.value) },
                    })
                  }
                  options={countryOptions}
                  className="w-100"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={
                    formData.type === "Free" || formData.type === "Trial"
                      ? 0
                      : formData.price
                  }
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter price"
                  disabled={
                    formData.type === "Free" || formData.type === "Trial"
                  }
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Validity (days)</Form.Label>
                <Form.Control
                  type="number"
                  name="validity"
                  value={formData.validity}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter validity in days"
                  min="0"
                />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="mt-4">Features</h5>
          {Object.keys(formData.features).map((feature) => (
            <Row key={feature} className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Form.Check
                  type="switch"
                  id={feature}
                  className="d-flex align-items-center gap-2"
                  label={feature.replace(/([A-Z])/g, " $1").trim()}
                  checked={formData.features[feature].isEnabled}
                  onChange={(e) =>
                    handleFeatureChange(feature, "isEnabled", e.target.checked)
                  }
                />
              </Col>
              <Col md={8}>
                <Form.Control
                  type="text"
                  placeholder="Feature description"
                  value={formData.features[feature].description}
                  onChange={(e) =>
                    handleFeatureChange(feature, "description", e.target.value)
                  }
                  disabled={!formData.features[feature].isEnabled}
                />
              </Col>
            </Row>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading
            ? isEditing
              ? "Updating..."
              : "Creating..."
            : isEditing
            ? "Update Package"
            : "Create Package"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePackages;
