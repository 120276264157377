import React, { useEffect, useState } from "react";
import { getAllJobsReq } from "../__requests/JobsRequest";
import DataTable from "react-data-table-component";

const JobsList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleGetAllJobs = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getAllJobsReq(page, limit);
      setJobs(response?.jobs);
      setTotalRows(response?.meta?.totaljobs || 0);
    } catch (error) {
      console.error("Error in fetching jobs data", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllJobs(page, perPage);
  }, []);

  const columns = [
    {
      name: "Job Title",
      selector: row => row.job_title,
      sortable: true,
    },
    {
      name: "Company",
      selector: row => row.companyData?.name,
      sortable: true,
    },
    {
      name: "Location",
      selector: row => row.job_location,
    },
    {
      name: "Job Type",
      selector: row => row.job_type,
    },
    {
      name: "Experience",
      selector: row => row.yearsOfExperience,
    },
    {
      name: "Salary Range",
      cell: row => `${row.companyData?.currency}${row.salary_from} - ${row.salary_to}`,
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
    handleGetAllJobs(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    handleGetAllJobs(page, newPerPage);
  };

  return (
    <div className="card">
      <div className="card-body">
        <DataTable
          title="Jobs Listing"
          columns={columns}
          data={jobs}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          persistTableHead
        />
      </div>
    </div>
  );
};

export default JobsList;
