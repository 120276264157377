import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import PostsList from "../components/Posts/PostsList";

const Post = () => {
  return (
    <MasterLayout>
      <PostsList />
    </MasterLayout>
  );
};

export default Post;
