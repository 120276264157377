import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { updateUserCoinRequest } from "../__requests/CoinRequests";
import { useSelector } from "react-redux";

const EditCoinModal = ({
  show,
  onClose,
  currentCoins,
  currentEnergy,
  onUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    coins: 0,
    energy: 0,
  });

  const userId = useSelector((state) => state.userIdReducer);

  useEffect(() => {
    if (show) {
      setFormData({
        coins: currentCoins || 0,
        energy: currentEnergy || 0,
      });
    }
  }, [show, currentCoins, currentEnergy]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseInt(value) || 0,
    }));
  };

  const handleUpdateStats = async () => {
    try {
      setIsLoading(true);
      const response = await updateUserCoinRequest(userId, {
        coins: parseInt(formData.coins),
        energy: parseInt(formData.energy),
      });

      if (response.success) {
        toast.success("User stats updated successfully!");
        if (typeof onUpdate === "function") {
          await onUpdate();
        }
        onClose();
      } else {
        toast.error(response.message || "Failed to update user stats");
      }
    } catch (error) {
      console.error("Update error:", error);
      toast.error(
        error?.response?.data?.message ||
        "An error occurred while updating stats"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!userId) {
      toast.error("User ID is required");
      return;
    }
    handleUpdateStats();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit User Coins & Energy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group className="mb-3">
            <Form.Label>Update Total Coins</Form.Label>
            <Form.Control
              type="number"
              name="coins"
              value={formData.coins}
              onChange={handleChange}
              min="1"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Update Total Energy</Form.Label>
            <Form.Control
              type="number"
              name="energy"
              value={formData.energy}
              onChange={handleChange}
              min="0"
              max="10"
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              onClick={handleSubmit}
              disabled={isLoading}
              type="button"
            >
              {isLoading ? "Updating..." : "Update Stats"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditCoinModal;
