import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  getFilteredReferralsReq,
  getReferralsReq,
} from "../__requests/ReferralRequests";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ScaleLoader } from "react-spinners";

const ReferralTable = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const columns = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * perPage + index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
      sortable: true,
    },
    {
      name: "Referrer Name",
      selector: (row) =>
        row.referrer
          ? `${row.referrer.first_name} ${row.referrer.last_name}`
          : "N/A",
      sortable: true,
    },
    {
      name: "Referrer Email",
      selector: (row) => row.referrer?.email || "N/A",
      sortable: true,
    },
    {
      name: "Referred Name",
      selector: (row) =>
        row.referred
          ? `${row.referred.first_name} ${row.referred.last_name}`
          : "N/A",
      sortable: true,
    },
    {
      name: "Referred Email",
      selector: (row) => row.referred?.email || "N/A",
      sortable: true,
    },
  ];

  const getReferrals = async (page, limit, searchParams = {}) => {
    try {
      setLoading(true);

      // Build query parameters
      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("limit", limit);

      if (searchParams.matchQuery) {
        queryParams.append("matchQuery", searchParams.matchQuery);
      }

      if (searchParams.month && searchParams.year) {
        queryParams.append("month", searchParams.month);
        queryParams.append("year", searchParams.year);
      }

      const response = await getFilteredReferralsReq(queryParams);
      if (response?.success) {
        setReferrals(response?.referrals || []);
        setTotalPages(response?.pagination?.totalPages || 1);
        setTotalRows(response?.pagination?.totalDocuments || 0);
      } else {
        setReferrals([]);
        setTotalPages(1);
        setTotalRows(0);
        console.error("Error fetching referrals:", response.message);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error in referral list", err);
      setReferrals([]);
      setLoading(false);
      setTotalPages(1);
      setTotalRows(0);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const value = e.target.value;
    setSearchText(value);
    setCurrentPage(1);

    const searchParams = {
      matchQuery: value,
    };

    if (selectedDate) {
      const [year, month] = selectedDate.split("-");
      searchParams.month = parseInt(month);
      searchParams.year = parseInt(year);
    }

    getReferrals(1, perPage, searchParams);
  };

  const handleDateSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSelectedDate(value);
    setCurrentPage(1);

    const searchParams = {};

    // Include text search if it exists
    if (searchText) {
      searchParams.matchQuery = searchText;
    }

    // Add date filters
    if (value) {
      const [year, month] = value.split("-");
      searchParams.month = parseInt(month);
      searchParams.year = parseInt(year);
    }

    getReferrals(1, perPage, searchParams);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const searchParams = {};

    if (searchText) {
      searchParams.matchQuery = searchText;
    }

    if (selectedDate) {
      const [year, month] = selectedDate.split("-");
      searchParams.month = parseInt(month);
      searchParams.year = parseInt(year);
    }

    getReferrals(page, perPage, searchParams);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    const searchParams = {};

    if (searchText) {
      searchParams.matchQuery = searchText;
    }

    if (selectedDate) {
      const [year, month] = selectedDate.split("-");
      searchParams.month = parseInt(month);
      searchParams.year = parseInt(year);
    }

    getReferrals(page, newPerPage, searchParams);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add title to the PDF
    doc.text("Referrals List", 14, 15);

    // Prepare the data for PDF
    const tableData = referrals.map((row, index) => [
      (currentPage - 1) * perPage + index + 1,
      new Date(row.createdAt).toLocaleDateString(),
      row.referrer?.email || "",
      row.referred
        ? `${row.referred.first_name} ${row.referred.last_name}`
        : "",
      row.referred?.email || "",
    ]);

    // Generate the table
    doc.autoTable({
      head: [
        ["#", "Date", "Referrer Email", "Referred Name", "Referred Email"],
      ],
      body: tableData,
      startY: 20,
    });

    // Save the PDF
    doc.save("referrals-list.pdf");
  };

  useEffect(() => {
    getReferrals(currentPage, perPage, {});
  }, []);

  const subHeaderComponent = (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="d-flex align-items-center justify-content-evenly w-100 gap-2"
    >
      <input
        type="text"
        placeholder="Search by name or email..."
        className="w-50 form-control"
        value={searchText}
        onChange={handleSearch}
        onPaste={(e) => {
          e.preventDefault();
          const pastedText = e.clipboardData.getData("text");
          setSearchText(pastedText);
          const searchParams = {
            matchQuery: pastedText,
          };
          if (selectedDate) {
            const [year, month] = selectedDate.split("-");
            searchParams.month = parseInt(month);
            searchParams.year = parseInt(year);
          }
          getReferrals(1, perPage, searchParams);
        }}
      />
      <input
        type="month"
        placeholder="Select month and year"
        className="w-50 form-control"
        value={selectedDate}
        onChange={handleDateSearch}
      />
      <button className="btn btn-primary" onClick={downloadPDF}>
        PDF
      </button>
    </form>
  );

  return (
    <DataTable
      title="Referrals List"
      columns={columns}
      data={referrals}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationPerPage={50}
      paginationRowsPerPageOptions={[50, 100, 150, 200, 500]}
      striped
      highlightOnHover
      persistTableHead
      responsive
      subHeader
      subHeaderComponent={subHeaderComponent}
      progressComponent={
        <ScaleLoader
          color="#0d6efd"
          height={35}
          width={4}
          radius={2}
          margin={2}
        />
      }
      noDataComponent={
        loading ? (
          <ScaleLoader
            color="#0d6efd"
            height={35}
            width={4}
            radius={2}
            margin={2}
          />
        ) : (
          "No users found"
        )
      }
    />
  );
};

export default ReferralTable;
