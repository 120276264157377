import React, { useEffect, useState } from "react";
import CreateStickers from "./CreateStickers";
import { getAllStickersReq } from "../__requests/StickersRequests";
import { IoTrashOutline } from "react-icons/io5";
import { Modal, Button } from "react-bootstrap";
import "./Stickers.css";
import StickerDelete from "./StickerDelete";
import { ScaleLoader } from "react-spinners";

const StickersList = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [stickers, setStickers] = useState([]);

  const getAllStickers = async () => {
    try {
      setLoading(true);
      const response = await getAllStickersReq();
      if (response?.success) {
        setStickers(response?.stickers || []);
      }
    } catch (err) {
      console.error("Error in fetching stickers", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStickers();
  }, []);

  const handleDeleteClick = (sticker, e) => {
    e.stopPropagation();
    setSelectedSticker(sticker);
    setDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    // Add your delete API call here
    // After successful deletion:
    setDeleteModal(false);
    setSelectedSticker(null);
    getAllStickers(); // Refresh the list
  };

  const handleCreateSuccess = (newSticker) => {
    setStickers((prevStickers) => [...prevStickers, newSticker]);
    handleClose();
  };

  const filteredStickers = stickers.filter((sticker) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      sticker.name.toLowerCase().includes(searchLower) ||
      sticker.value.toString().includes(searchLower)
    );
  });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5 className="card-title mb-0">List of Stickers</h5>
            <div className="d-flex align-items-center gap-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search by name or value..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <button
                className="btn btn-primary text-nowrap"
                onClick={handleShow}
              >
                Create New Stickers
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center py-5">
              <ScaleLoader color="#0d6efd" />
            </div>
          ) : filteredStickers.length === 0 ? (
            <div className="text-center py-5">
              <h4 className="text-muted">No Stickers Found</h4>
            </div>
          ) : (
            <div className="row g-3">
              {filteredStickers?.map((sticker) => (
                <div key={sticker?._id} className="col-md-4 col-lg-3">
                  <div className="sticker-list-item">
                    <div className="sticker-list-image-wrapper">
                      <div className="sticker-list-image-container">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${sticker?.image}`}
                          alt={sticker?.name}
                          className="sticker-list-image"
                        />
                        <div
                          className="sticker-list-delete-overlay"
                          onClick={(e) => handleDeleteClick(sticker, e)}
                        >
                          <IoTrashOutline className="text-white fs-3" />
                        </div>
                      </div>
                    </div>
                    <div className="sticker-list-content">
                      <h5 className="sticker-title">{sticker?.name}</h5>
                      <p className="sticker-value">Value: {sticker?.value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <CreateStickers
        show={showModal}
        onClose={handleClose}
        onCreateSuccess={handleCreateSuccess}
      />

      <StickerDelete
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        selectedSticker={selectedSticker}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

export default StickersList;
