import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import PackagesList from "../components/Packages/PackagesList";

const Packages = () => {
  return (
    <MasterLayout>
      <PackagesList />
    </MasterLayout>
  );
};

export default Packages;
