import axiosInstance from "../../api/AxiosInstance";

export const getAllPosts = async (pageNumber, limit) => {
  try {
    const response = await axiosInstance.get(`/social/post/all/admin?page=${pageNumber}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching posts data", error.message);
    return { data: null, error: "Error in fetching posts" };
  }
};
