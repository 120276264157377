import axiosInstance from "../../api/AxiosInstance";

export const getSearchFilterReq = async (query, isCountrySearch = false) => {
    try {
      const response = await axiosInstance.get(
        `/search/all/social/items?q=${query}&isCountrySearch=${isCountrySearch}`
      );
      
      return response.data;
    } catch (error) {
      console.error("Error in fetching Product List", error.message);
      return { data: null, error: "Error fetch Product List" };
    }
  };
  