import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import AllUsersList from "./Pages/AllUsersList";
import ReferralList from "./Pages/ReferralList";
import Packages from "./Pages/Packages";
import UserProfile from "./Pages/UserProfile";
import Stickers from "./Pages/Stickers";
import Companies from "./Pages/Companies";
import Post from "./Pages/Post";
import Jobs from "./Pages/Jobs";
import CompanyProfile from "./components/Companies/CompanyProfile";

// Protected Route for authenticated users
const ProtectedAdminRoute = ({ children }) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const isAdmin = localStorage.getItem("role") === "admin";

  if (!token || !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Public Route (Login page)
const PublicRoute = ({ children }) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const isAdmin = localStorage.getItem("role") === "admin";

  // Redirect to dashboard if authenticated
  if (token && isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

function App() {
  // Initialize the API request flag
  window.isApiRequest = false;

  // Check for token
  const localToken = localStorage.getItem("token");
  const sessisnToken = sessionStorage.getItem("token");

  if (!localToken && !sessisnToken) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedAdminRoute>
              <Dashboard />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/all-users"
          element={
            <ProtectedAdminRoute>
              <AllUsersList />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/referral-users"
          element={
            <ProtectedAdminRoute>
              <ReferralList />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <ProtectedAdminRoute>
              <Packages />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/user-profile/:id"
          element={
            <ProtectedAdminRoute>
              <UserProfile />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/stickers"
          element={
            <ProtectedAdminRoute>
              <Stickers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/companies"
          element={
            <ProtectedAdminRoute>
              <Companies />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/posts"
          element={
            <ProtectedAdminRoute>
              <Post />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <ProtectedAdminRoute>
              <Jobs />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/company-profile/:id"
          element={
            <ProtectedAdminRoute>
              <CompanyProfile />
            </ProtectedAdminRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
