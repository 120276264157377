import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllPosts } from "../__requests/PostRequests";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PostsList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const columns = [
    {
      name: "#",
      cell: (row, index) => (page - 1) * perPage + index + 1,
      width: "60px",
    },
    {
      name: "Media",
      cell: (row) => (
        <div
          style={{
            width: "200px",
            height: "150px",
            padding: "5px",
            overflow: "hidden",
          }}
        >
          {row?.isReel || row.isVideo ? (
            <div style={{ height: "100%", width: "100%" }}>
              <video
                width="100%"
                height="100%"
                controls
                style={{
                  objectFit: "contain",
                  maxHeight: "140px",
                }}
              >
                <source
                  src={`${process.env.REACT_APP_IMAGE_URL}/${row?.reel}`}
                  type="video/mp4"
                />
              </video>
            </div>
          ) : row.existingPhotos && row.existingPhotos.length > 0 ? (
            row.existingPhotos.length === 1 ? (
              <div style={{ height: "100%", width: "100%" }}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${row.existingPhotos[0].url}`}
                  alt="post"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    maxHeight: "140px",
                  }}
                />
              </div>
            ) : (
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={true}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                stopOnHover={true}
                style={{ height: "100%" }}
                className="custom-carousel"
              >
                {row.existingPhotos.map((photo, index) => (
                  <div
                    key={index}
                    style={{
                      height: "140px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${photo.url}`}
                      alt={`post-${index}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "140px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            )
          ) : (
            <div>No media</div>
          )}
        </div>
      ),
      width: "220px",
    },
    {
      name: "Posted By",
      selector: (row) => {
        if (row.userData && row.userData[0]) {
          return `${row.userData[0].first_name} ${row.userData[0].last_name}`;
        }
        return "Unknown User";
      },
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.content || "No content",
      sortable: true,
    },
    {
      name: "Posted On",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Reactions",
      selector: (row) => row.reactions?.length || 0,
      sortable: true,
    },
  ];

  const handleGetAllPosts = async (pageNumber = 1, limit = 10) => {
    try {
      setLoading(true);
      const response = await getAllPosts(pageNumber, limit);
      console.log("responsePosts", response);
      if (response?.posts) {
        setPosts(response?.posts);
        setTotalRows(response?.meta?.totalPosts);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
    handleGetAllPosts(pageNum, perPage);
  };

  const handlePerRowsChange = async (newPerPage, pageNum) => {
    setPerPage(newPerPage);
    handleGetAllPosts(pageNum, newPerPage);
  };

  useEffect(() => {
    handleGetAllPosts(page, perPage);
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <DataTable
          title="Posts List"
          columns={columns}
          data={posts}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          responsive
          striped
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default PostsList;
