import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import AllUsersTableList from "../components/Users-Data/AllUsersTableList";

const AllUsersList = () => {
  return (
    <MasterLayout>
      <AllUsersTableList />
    </MasterLayout>
  );
};

export default AllUsersList;
