import axiosInstance from "../../api/AxiosInstance";

export const getUserCoinRequest = async (id) => {
  try {
    const response = await axiosInstance.get(`/earn-coin/total-coins/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const updateUserCoinRequest = async (id, data) => {
  try {
    const response = await axiosInstance.put(
      `/earn-coin/admin/update-user-stats/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user stats:", error);
    throw error;
  }
};
