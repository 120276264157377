import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import {
  getCommentRepliesReq,
  getCommentsReq,
} from "../__requests/CommentRequests";
import { ScaleLoader } from "react-spinners";
import "./UserPosts.css";

const getTimeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  let interval = seconds / 31536000; // years
  if (interval > 1) return Math.floor(interval) + " y ago";

  interval = seconds / 2592000; // months
  if (interval > 1) return Math.floor(interval) + " m ago";

  interval = seconds / 86400; // days
  if (interval > 1) return Math.floor(interval) + " d ago";

  interval = seconds / 3600; // hours
  if (interval > 1) return Math.floor(interval) + " h ago";

  interval = seconds / 60; // minutes
  if (interval > 1) return Math.floor(interval) + " min ago";

  return Math.floor(seconds) + " seconds ago";
};

const PostCommentsModal = ({ show, onClose, postId }) => {
  const [comments, setComments] = useState([]);
  const [repliesMap, setRepliesMap] = useState({});
  const [loadingReplies, setLoadingReplies] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const [repliesPageMap, setRepliesPageMap] = useState({});
  const [hasMoreRepliesMap, setHasMoreRepliesMap] = useState({});
  const repliesLimit = 5;
  const [showRepliesMap, setShowRepliesMap] = useState({});

  // Reference for intersection observer
  const observer = useRef();
  const lastCommentRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleGetComments = async (pageNum) => {
    if (!postId) return;

    try {
      setLoading(true);
      const response = await getCommentsReq(postId, pageNum, limit);
      console.log("responseComments", response);

      // Append new comments to existing ones
      setComments((prev) =>
        pageNum === 1 ? response.comments : [...prev, ...response.comments]
      );

      // Check if there are more pages
      setHasMore(response.comments.length === limit);
    } catch (error) {
      setError("Error in fetching comments");
      console.log("Error in fetching comments", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show && postId) {
      setPage(1);
      setComments([]);
      setHasMore(true);
      handleGetComments(1);
    }
  }, [show, postId]);

  useEffect(() => {
    if (page > 1) {
      handleGetComments(page);
    }
  }, [page]);

  // Reset state when modal closes
  useEffect(() => {
    if (!show) {
      setComments([]);
      setPage(1);
      setError(null);
      setHasMore(true);
    }
  }, [show]);

  const handleGetCommentReplies = async (commentId) => {
    try {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: true }));
      const response = await getCommentRepliesReq(commentId, 1, 5);
      setRepliesMap((prev) => ({
        ...prev,
        [commentId]: response.replies,
      }));
    } catch (error) {
      console.log("Error in fetching comment replies", error.message);
    } finally {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: false }));
    }
  };

  useEffect(() => {
    if (show && postId) {
      handleGetCommentReplies(postId);
    }
  }, [show, postId]);

  const handleViewReplies = async (commentId) => {
    // Toggle show/hide replies
    if (showRepliesMap[commentId]) {
      setShowRepliesMap((prev) => ({
        ...prev,
        [commentId]: false,
      }));
      return;
    }

    try {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: true }));
      const response = await getCommentRepliesReq(commentId, 1, repliesLimit);
      setRepliesMap((prev) => ({
        ...prev,
        [commentId]: response.replies,
      }));
      setRepliesPageMap((prev) => ({
        ...prev,
        [commentId]: 1,
      }));
      setHasMoreRepliesMap((prev) => ({
        ...prev,
        [commentId]: response.replies.length === repliesLimit,
      }));
      // Show replies after loading
      setShowRepliesMap((prev) => ({
        ...prev,
        [commentId]: true,
      }));
    } catch (error) {
      console.log("Error in fetching comment replies", error.message);
    } finally {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: false }));
    }
  };

  const handleLoadMoreReplies = async (commentId) => {
    try {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: true }));
      const nextPage = (repliesPageMap[commentId] || 1) + 1;

      const response = await getCommentRepliesReq(
        commentId,
        nextPage,
        repliesLimit
      );

      setRepliesMap((prev) => ({
        ...prev,
        [commentId]: [...(prev[commentId] || []), ...response.replies],
      }));

      setRepliesPageMap((prev) => ({
        ...prev,
        [commentId]: nextPage,
      }));

      setHasMoreRepliesMap((prev) => ({
        ...prev,
        [commentId]: response.replies.length === repliesLimit,
      }));
    } catch (error) {
      console.log("Error in fetching more replies", error.message);
    } finally {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: false }));
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      dialogClassName="comments-modal-container"
    >
      <Modal.Header closeButton>
        <Modal.Title>Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <div className="text-danger text-center">{error}</div>
        ) : comments.length === 0 && !loading ? (
          <div className="text-center text-muted">No comments yet</div>
        ) : (
          <div className="comments-container">
            {comments.map((comment, index) => (
              <div
                key={comment._id}
                ref={index === comments.length - 3 ? lastCommentRef : null}
                className="comment-item"
              >
                <div className="comment-header">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${comment?.profilePicture}`}
                    alt="Profile"
                    className="comment-avatar"
                  />
                  <div className="comment-user-info">
                    <h6>{comment?.name}</h6>
                    <small>{getTimeAgo(comment.createdAt)}</small>
                  </div>
                </div>
                <div className="comment-content">
                  <p>{comment.content}</p>
                  {comment.replyphotos && comment.replyphotos.length > 0 && (
                    <div className="comment-images">
                      {comment.replyphotos.map((photo, photoIndex) => (
                        <img
                          key={photoIndex}
                          src={`${process.env.REACT_APP_IMAGE_URL}/${photo.url}`}
                          alt={`Comment attachment ${photoIndex + 1}`}
                          className="comment-attached-image"
                        />
                      ))}
                    </div>
                  )}
                </div>
                {comment.replyCount > 0 && (
                  <button
                    className="view-replies-btn"
                    onClick={() => handleViewReplies(comment._id)}
                  >
                    <i
                      className={`fas ${
                        showRepliesMap[comment._id]
                          ? "fa-chevron-up"
                          : "fa-reply"
                      } me-1`}
                    ></i>
                    {showRepliesMap[comment._id] ? "Hide" : "View"}{" "}
                    {comment.replyCount}{" "}
                    {comment.replyCount === 1 ? "reply" : "replies"}
                  </button>
                )}

                {loadingReplies[comment._id] && (
                  <div className="replies-loading">
                    <ScaleLoader color="#1976d2" height={15} />
                  </div>
                )}

                {showRepliesMap[comment._id] &&
                  repliesMap[comment._id] &&
                  repliesMap[comment._id].length > 0 && (
                    <div className="replies-section">
                      {repliesMap[comment._id].map((reply) => (
                        <div key={reply._id} className="reply-item">
                          <div className="comment-header">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${reply?.profilePicture}`}
                              alt="Profile"
                              className="comment-avatar"
                            />
                            <div className="comment-user-info">
                              <h6>{reply?.name}</h6>
                              <small>{getTimeAgo(reply.createdAt)}</small>
                            </div>
                          </div>
                          <div className="reply-content">{reply.content}</div>
                        </div>
                      ))}

                      {hasMoreRepliesMap[comment._id] && (
                        <button
                          className="load-more-replies-btn"
                          onClick={() => handleLoadMoreReplies(comment._id)}
                          disabled={loadingReplies[comment._id]}
                        >
                          Show more replies
                        </button>
                      )}
                    </div>
                  )}
              </div>
            ))}

            {loading && (
              <div className="text-center p-3">
                <ScaleLoader color="#1976d2" />
              </div>
            )}

            {!loading && !hasMore && comments.length > 0 && (
              <div className="text-center text-muted p-3">
                No more comments to load
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PostCommentsModal;
