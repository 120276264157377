import React, { useEffect, useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import FruityDetailCards from "../components/Dashboard/FruityDetailCards";
import { getUserToConnectReq } from "../components/__requests/UserRequests";
import { getCompaniestReq } from "../components/__requests/CompanyRequests";
import { getPostsReq } from "../components/__requests/Postsrequests";
import { getJobsReq } from "../components/__requests/JobsRequests";
import { getProductsReq } from "../components/__requests/ProductsRequests";
import TopCountries from "../components/Dashboard/TopCountries";
import countries from "i18n-iso-countries";
import Flag from "react-world-flags";
import { darsboardReq } from "../components/__requests/DashboardRequests";

// Initialize the library with English translations
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Dashboard = () => {
  const [dashboardCount, setDashboardCount] = useState([]);
  const handleDashboard = async () => {
    const response = await darsboardReq();
    setDashboardCount(response);
  };

  useEffect(() => {
    handleDashboard();
  }, []);

  return (
    <MasterLayout>
      <FruityDetailCards dashboardCount={dashboardCount} />
      <TopCountries />
    </MasterLayout>
  );
};

export default Dashboard;
