import axiosInstance from "../../api/AxiosInstance";

export const getReferralsReq = async (page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/refral/all/referral-users?page=${page}&limit=${limit}`
    );
    return {
      success: true,
      ...response.data
    };
  } catch (error) {
    return {
      success: false,
      referrals: [],
      pagination: {
        totalPages: 1,
        totalDocuments: 0
      },
      message: error.message
    };
  }
};

export const getFilteredReferralsReq = async (queryParams) => {
  try {
    const response = await axiosInstance.get(
      `/refral/filtered/referral-users?${queryParams}`
    );
    
    return {
      success: true,
      referrals: response.data?.referrals || [],
      pagination: response.data?.pagination || {
        totalPages: 1,
        totalDocuments: 0
      }
    };
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.error("Authentication error:", error.message);
    }
    
    return {
      success: false,
      referrals: [],
      pagination: {
        totalPages: 1,
        totalDocuments: 0
      },
      message: error.message
    };
  }
};
