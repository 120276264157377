import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import StickersList from "../components/stickers/StickersList";

const Stickers = () => {
  return (
    <MasterLayout>
      <StickersList />
    </MasterLayout>
  );
};

export default Stickers;
