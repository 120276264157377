import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, NavLink, useLocation } from "react-router-dom";
import { GoCrossReference } from "react-icons/go";
import { MdOutlinePayment } from "react-icons/md";
import { useSelector } from "react-redux";
import { PiBuildingApartmentFill, PiSmileySticker } from "react-icons/pi";
import { BsFilePost } from "react-icons/bs";
import { IoBriefcase } from "react-icons/io5";

const MasterLayout = ({ children }) => {
  let [sidebarActive, seSidebarActive] = useState(false);
  let [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleDropdownClick = (event) => {
      event.preventDefault();
      const clickedLink = event.currentTarget;
      const clickedDropdown = clickedLink.closest(".dropdown");

      if (!clickedDropdown) return;

      const isActive = clickedDropdown.classList.contains("open");

      // Close all dropdowns
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        dropdown.classList.remove("open");
      });

      // Toggle the clicked dropdown
      if (!isActive) {
        clickedDropdown.classList.add("open");
      }
    };

    const dropdownTriggers = document.querySelectorAll(
      ".sidebar-menu .dropdown > a, .sidebar-menu .dropdown > Link"
    );

    dropdownTriggers.forEach((trigger) => {
      trigger.addEventListener("click", handleDropdownClick);
    });

    // Function to open submenu based on current route
    const openActiveDropdown = () => {
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        const submenuLinks = dropdown.querySelectorAll(".sidebar-submenu li a");
        submenuLinks.forEach((link) => {
          if (
            link.getAttribute("href") === location.pathname ||
            link.getAttribute("to") === location.pathname
          ) {
            dropdown.classList.add("open");
          }
        });
      });
    };

    // Open the submenu that contains the open route
    openActiveDropdown();

    // Cleanup event listeners on unmount
    return () => {
      dropdownTriggers.forEach((trigger) => {
        trigger.removeEventListener("click", handleDropdownClick);
      });
    };
  }, [location.pathname]);

  let sidebarControl = () => {
    seSidebarActive(!sidebarActive);
  };

  let mobileMenuControl = () => {
    setMobileMenu(!mobileMenu);
  };

  const userData = useSelector((state) => state.userDataReducer);

  return (
    <section className={mobileMenu ? "overlay active" : "overlay "}>
      {/* sidebar */}
      <aside
        className={
          sidebarActive
            ? "sidebar active "
            : mobileMenu
            ? "sidebar sidebar-open"
            : "sidebar"
        }
      >
        <button
          onClick={mobileMenuControl}
          type="button"
          className="sidebar-close-btn"
        >
          <Icon icon="radix-icons:cross-2" />
        </button>
        <div>
          <Link to="/" className="sidebar-logo">
            <img
              src="/Fruity_Chat_new_logo.svg"
              alt="site logo"
              className="light-logo"
            />
            <img
              src="/Fruity_Chat_new_logo.svg"
              alt="site logo"
              className="dark-logo"
            />
            <img
              src="/fav_icon_blue.svg"
              alt="site logo"
              className="logo-icon"
            />
          </Link>
        </div>
        <div className="sidebar-menu-area">
          <ul className="sidebar-menu" id="sidebar-menu">
            <li>
              <NavLink
                to="/dashboard"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <Icon
                  icon="solar:home-smile-angle-outline"
                  className="menu-icon"
                />
                <span>Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/all-users"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <Icon icon="fa-solid:users" className="menu-icon" />
                <span>Users</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Companies"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <PiBuildingApartmentFill className="menu-icon" />
                <span>Companies</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/posts"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <BsFilePost className="menu-icon" />
                <span>Posts</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/jobs"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <IoBriefcase className="menu-icon" />
                <span>Jobs</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/referral-users"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <GoCrossReference className="menu-icon" />
                <span>Referrals</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/stickers"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <PiSmileySticker className="menu-icon" />
                <span>Stickers</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/plans"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <MdOutlinePayment className="menu-icon" />
                <span>Plans</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>

      <main
        className={sidebarActive ? "dashboard-main active" : "dashboard-main"}
      >
        <div className="navbar-header">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="d-flex flex-wrap align-items-center gap-4">
                <button
                  type="button"
                  className="sidebar-toggle"
                  onClick={sidebarControl}
                >
                  {sidebarActive ? (
                    <Icon
                      icon="iconoir:arrow-right"
                      className="icon text-2xl non-active"
                    />
                  ) : (
                    <Icon
                      icon="heroicons:bars-3-solid"
                      className="icon text-2xl non-active "
                    />
                  )}
                </button>
                <button
                  onClick={mobileMenuControl}
                  type="button"
                  className="sidebar-mobile-toggle"
                >
                  <Icon icon="heroicons:bars-3-solid" className="icon" />
                </button>
                <form className="navbar-search">
                  <input type="text" name="search" placeholder="Search" />
                  <Icon icon="ion:search-outline" className="icon" />
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex flex-wrap align-items-center gap-3">
                {/* Notification dropdown end */}
                <div className="dropdown">
                  <button
                    className="d-flex justify-content-center align-items-center rounded-circle"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      src={
                        userData?.profilePicture
                          ? userData?.profilePicture.includes(
                              "https://lh3.googleusercontent"
                            )
                            ? userData?.profilePicture
                            : `${process.env.REACT_APP_IMAGE_URL}/${userData?.profilePicture}`
                          : "/Default_user_Image.png"
                      }
                      alt="image_user"
                      className="w-40-px h-40-px object-fit-cover rounded-circle"
                    />
                  </button>
                  <div className="dropdown-menu to-top dropdown-menu-sm">
                    <div className="py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                      <div>
                        <span className="text-secondary-light fw-medium text-sm">
                          {userData?.first_name} {userData?.last_name}
                        </span>
                      </div>
                    </div>
                    <ul className="to-top-list">
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                          to="/view-profile"
                        >
                          <Icon
                            icon="solar:user-linear"
                            className="icon text-xl"
                          />{" "}
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-danger d-flex align-items-center gap-3"
                          to="/"
                          onClick={() => {
                            localStorage.removeItem("token");
                            sessionStorage.removeItem("token");
                            // You might want to clear other auth-related data as well
                            // localStorage.removeItem('user');
                            // sessionStorage.removeItem('user');
                          }}
                        >
                          <Icon icon="lucide:power" className="icon text-xl" />{" "}
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Profile dropdown end */}
              </div>
            </div>
          </div>
        </div>

        {/* dashboard-main-body */}
        <div className="dashboard-main-body">{children}</div>
      </main>
    </section>
  );
};

export default MasterLayout;
