import React, { useEffect, useState } from "react";
import { getUserToConnectReq, deleteUserReq } from "../__requests/UserRequests";
import DataTable from "react-data-table-component";
import { getSearchFilterReq } from "../__requests/SearchRequest";
import { customList } from "country-codes-list";
import { parsePhoneNumber } from "libphonenumber-js";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { userIdDispatcher } from "../../redux/action";
import { useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";

const countryNameList = customList("countryCode", "{countryNameEn}");

const getCountryName = (countryCode) => {
  if (!countryCode) return "";
  return countryNameList[countryCode.toUpperCase()] || countryCode;
};

const formatPhoneNumber = (phone, countryCode) => {
  if (!phone) return "";
  try {
    // If phone doesn't start with +, add it along with country code
    const phoneWithCode = phone.startsWith("+") ? phone : `+${phone}`;
    const phoneNumber = parsePhoneNumber(phoneWithCode);
    return phoneNumber.formatInternational();
  } catch (error) {
    console.error("Error formatting phone number:", error);
    return phone;
  }
};

const AllUsersTableList = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const dispatch = useDispatch();

  const countryOptions = Object.entries(countryNameList).map(
    ([code, name]) => ({
      value: code,
      label: name,
    })
  );

  const columns = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * 10 + index + 1,
      width: "70px",
      sortable: false,
    },
    {
      name: "Image",
      cell: (row) => row.image ? (
        <img 
          src={row.image}
          alt="User"
          style={{ 
            width: '40px', 
            height: '40px', 
            objectFit: 'cover',
            borderRadius: '50%'
          }}
        />
      ) : null,
      width: "80px",
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => {
        if (row.name) return row.name;
        const firstName = row.first_name || "";
        const lastName = row.last_name || "";
        return `${firstName} ${lastName}`.trim() || "";
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => formatPhoneNumber(row.phone, row.address_country),
    },
    {
      name: "Country",
      selector: (row) => getCountryName(row.address_country),
      sortable: true,
    },
    {
      name: "Coins",
      selector: (row) => row?.coinInfo?.coins || row?.coinInfo?.totalCoins || 0,
      sortable: true,
    },
    {
      name: "Profile",
      cell: (row) => (
        <Link
          to={`/user-profile/${row._id}`}
          onClick={() => dispatch(userIdDispatcher(row?._id))}
        >
          <button
            className="btn btn-primary btn-sm p-1 d-flex align-items-center justify-content-center"
            // onClick={() => handleDeleteUser(row._id)}
            disabled={!row._id}
          >
            <FaUserAlt />
          </button>
        </Link>
      ),
      width: "100px",
      sortable: false,
    },
  ];

  const getUsers = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getUserToConnectReq(page, limit);
      setAllUsers(response?.users || []);
      setTotalRows(response?.meta?.totalUsers || 0);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    if (!isSearching) {
      await getUsers(page, newPerPage);
    }
  };

  const handleSearch = async (
    searchText,
    countryCode = selectedCountry?.value
  ) => {
    setFilterText(searchText);

    // If country is selected, use exact country code
    if (countryCode) {
      setIsSearching(true);
      setLoading(true);
      try {
        const response = await getSearchFilterReq(countryCode, true);
        if (response?.users) {
          setAllUsers(response?.users);
          setTotalRows(response?.users?.length);
        }
      } catch (error) {
        console.error("Error searching users:", error);
      } finally {
        setLoading(false);
      }
    }
    // For text search, only proceed if text is long enough
    else if (searchText?.length >= 2) {
      setIsSearching(true);
      setLoading(true);
      try {
        const response = await getSearchFilterReq(searchText);
        console.log("getSearchFilterReq", response);
        if (response?.users) {
          setAllUsers(response?.users);
          setTotalRows(response?.users?.length);
        }
      } catch (error) {
        console.error("Error searching users:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsSearching(false);
      getUsers(currentPage, perPage);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (!selectedOption && !filterText) {
      setIsSearching(false);
      getUsers(currentPage, perPage);
    } else {
      handleSearch(filterText, selectedOption?.value);
    }
  };

  const subHeaderComponent = (
    <div className="d-flex align-items-center justify-content-evenly w-100 gap-2">
      <input
        type="text"
        placeholder="Search users..."
        value={filterText}
        onChange={(e) => handleSearch(e.target.value)}
        className="w-50 form-control"
      />
      <Select
        isClearable
        placeholder="Select Country"
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
        className="w-50"
      />
    </div>
  );

  useEffect(() => {
    if (!isSearching) {
      getUsers(currentPage, perPage);
    }
  }, [currentPage, isSearching, perPage]);

  return (
    <div className="card">
      <div className="card-body">
        <DataTable
          title="Users List"
          columns={columns}
          data={allUsers}
          pagination
          paginationServer={!isSearching}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          progressPending={loading}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 500]}
          subHeader
          subHeaderComponent={subHeaderComponent}
          persistTableHead
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={perPage}
          progressComponent={
            <ScaleLoader
              color="#0d6efd"
              height={25}
              width={3}
              radius={2}
              margin={2}
            />
          }
          noDataComponent={
            loading ? (
              <ScaleLoader
                color="#0d6efd"
                height={35}
                width={4}
                radius={2}
                margin={2}
              />
            ) : (
              "No users found"
            )
          }
          highlightOnHover
          striped
          responsive
        />
      </div>
    </div>
  );
};

export default AllUsersTableList;
