import React, { useEffect, useState, useRef, useCallback } from "react";
import { getUserPostsReq } from "../__requests/Postsrequests";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./UserPosts.css";
import { ScaleLoader } from "react-spinners";
import PostCommentsModal from "./PostCommentsModal";
import { GoComment } from "react-icons/go";

const UserPosts = ({ id }) => {
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);

  // Reference for intersection observer
  const observer = useRef();
  const lastPostRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleGetUserPosts = async (pageNum) => {
    try {
      setLoading(true);
      const response = await getUserPostsReq(id, pageNum, 10);
      console.log("responseUserposts", response);
      const { posts, meta } = response;

      setUserPosts((prev) => (pageNum === 1 ? posts : [...prev, ...posts]));
      setHasMore(pageNum < meta.totalPages);
    } catch (error) {
      setError("Error in fetching user posts");
      console.log("Error in fetching user posts", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserPosts(page);
  }, [page]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Add this function to get emoji based on reaction type
  const getReactionEmoji = (type) => {
    switch (type) {
      case "like":
        return "/like.gif";
      case "love":
        return "/love.gif";
      case "haha":
        return "/laugh.gif";
      case "wow":
        return "/awsom.gif";
      case "sad":
        return "/sad.gif";
      case "angry":
        return "/angry.gif";
      default:
        return "/like.gif";
    }
  };

  // Add this function to count reactions by type
  const getReactionsByType = (reactions) => {
    return reactions.reduce((acc, reaction) => {
      const { type } = reaction;
      if (!acc[type]) {
        acc[type] = {
          count: 1,
          emoji: getReactionEmoji(type),
        };
      } else {
        acc[type].count += 1;
      }
      return acc;
    }, {});
  };

  // Modify the post footer section
  const getReactionsDisplay = (reactions) => {
    const reactionsByType = getReactionsByType(reactions || []);
    const totalCount = Object.values(reactionsByType).reduce(
      (sum, { count }) => sum + count,
      0
    );

    return (
      <div className="reactions-container">
        <div className="reaction-emojis">
          {Object.values(reactionsByType).map(({ emoji }, index) => (
            <img
              key={index}
              src={emoji}
              alt="reaction"
              className="reaction-emoji"
            />
          ))}
        </div>
        {totalCount > 0 && <span className="reaction-count">{totalCount}</span>}
      </div>
    );
  };

  // Add this handler for comment clicks
  const handleCommentClick = (postId) => {
    setSelectedPostId(postId);
    setShowCommentsModal(true);
  };

  // Add this handler for modal close
  const handleCloseModal = () => {
    setShowCommentsModal(false);
    setSelectedPostId(null);
  };

  if (loading && userPosts.length === 0) {
    return (
      <div
        className="loader-container"
        style={{ textAlign: "center", padding: "2rem" }}
      >
        <ScaleLoader color="#1976d2" />
      </div>
    );
  }

  if (!loading && userPosts.length === 0) {
    return (
      <div
        className="no-posts-message"
        style={{ textAlign: "center", padding: "2rem" }}
      >
        No posts from this user yet
      </div>
    );
  }

  return (
    <>
      <div className="posts-container">
        {error && <div className="error-message">{error}</div>}

        {userPosts.map((post, index) => (
          <div
            ref={
              index === Math.floor(userPosts?.length * 0.7) ? lastPostRef : null
            }
            key={post._id}
            className="post-card"
          >
            <div className="post-header">
              {post.userData[0]?.profilePicture && (
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${post.userData[0].profilePicture}`}
                  alt="Profile"
                  className="profile-photo"
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              )}
              <div className="post-info">
                <h6>
                  {post.userData[0]?.first_name} {post.userData[0]?.last_name}
                </h6>
                <p className="post-date">{formatDate(post.createdAt)}</p>
              </div>
            </div>

            {post.content && (
              <div className="post-content">
                <p>{post.content}</p>
              </div>
            )}

            <div className="post-media">
              {post.isReel ? (
                <video
                  controls
                  className="reel-video"
                  style={{
                    width: "100%",
                    maxHeight: "600px",
                    objectFit: "contain",
                  }}
                >
                  <source
                    src={`${process.env.REACT_APP_IMAGE_URL}/${post.reel}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                post?.existingPhotos?.length > 0 && (
                  <>
                    {post.existingPhotos.length === 1 ? (
                      <div className="single-image-container">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${post.existingPhotos[0].url}`}
                          alt="Post"
                          className="post-image"
                        />
                      </div>
                    ) : (
                      <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showArrows={true}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={3000}
                        stopOnHover={true}
                        className="custom-carousel"
                      >
                        {post.existingPhotos.map((photo, photoIndex) => (
                          <div
                            key={photoIndex}
                            className="carousel-image-container"
                          >
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${photo.url}`}
                              alt={`Post ${photoIndex + 1}`}
                              className="carousel-image"
                            />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </>
                )
              )}
            </div>

            <div className="post-footer">
              {getReactionsDisplay(post?.reactions)}
              <div
                className="post-stats"
                onClick={() => handleCommentClick(post._id)}
                style={{ cursor: "pointer" }}
              >
                <button className="comments-button">
                  <GoComment />
                  <span>{post.commentCount || 0} Comments</span>
                </button>
              </div>
            </div>
          </div>
        ))}

        {loading && (
          <div
            className="loading"
            style={{ textAlign: "center", padding: "1rem" }}
          >
            <ScaleLoader color="#1976d2" />
          </div>
        )}

        {!loading && !hasMore && userPosts.length > 0 && (
          <div
            className="no-more-posts"
            style={{ textAlign: "center", padding: "1rem" }}
          >
            No more posts to load
          </div>
        )}
      </div>

      <PostCommentsModal
        show={showCommentsModal}
        onClose={handleCloseModal}
        postId={selectedPostId}
      />
    </>
  );
};

export default UserPosts;
