import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import AllCompaniesList from "../components/Companies/AllCompaniesList";

const Companies = () => {
  return (
    <MasterLayout>
      <AllCompaniesList />
    </MasterLayout>
  );
};

export default Companies;
