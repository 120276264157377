import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createStickersReq } from "../__requests/StickersRequests";
import { IoTrashOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import "./Stickers.css";

const CreateStickers = ({ show, onClose, onCreateSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [createStickers, setCreateStickers] = useState({
    name: "",
    image: "",
    value: "",
  });

  const [imageSrc, setImageSrc] = useState(null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateStickers((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("name", createStickers.name);
      formData.append("value", createStickers.value);
      formData.append("image", selectedFile);

      const response = await createStickersReq(formData);
      if (response?.success) {
        onCreateSuccess(response.sticker);

        setCreateStickers({
          name: "",
          image: "",
          value: "",
        });
        setSelectedFile(null);
        setImageSrc(null);
      }
    } catch (error) {
      console.error("Error creating sticker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Sticker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3 d-flex align-items-center flex-column">
            <label htmlFor="tournamentPoster" className="form-label">
              Sticker Image
            </label>
            <input
              type="file"
              className="d-none"
              id="tournamentPoster"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              required
            />
            <div
              className="border-secondary border-1 rounded-2 d-flex align-items-center justify-content-center overflow-hidden"
              style={{ height: "180px", width: "180px", cursor: "pointer" }}
              onClick={handleDivClick}
            >
              {imageSrc ? (
                <div className="sticker-create-container position-relative w-100 h-100">
                  <img
                    src={imageSrc}
                    alt="Preview"
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                  />
                  <div
                    className="sticker-create-delete-overlay"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageSrc(null);
                      setSelectedFile(null);
                    }}
                  >
                    <IoTrashOutline className="fs-3 text-white" />
                  </div>
                </div>
              ) : (
                <FaPlus className="fs-3" />
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={createStickers.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Value</label>
            <input
              type="number"
              className="form-control"
              name="value"
              value={createStickers.value}
              onChange={handleInputChange}
              onWheel={(e) => e.target.blur()}
              min="0"
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              required
            />
          </div>
        </form>

        <div className="d-flex align-items-center justify-content-center w-100">
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            className="w-75"
          >
            {isLoading ? "Creating..." : "Create Sticker"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStickers;
