import axiosInstance from "../../api/AxiosInstance";

export const createStickersReq = async (data) => {
  try {
    const response = await axiosInstance.post(`/sticker/create-sticker`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating sticker:", error.message);
    throw error;
  }
};

export const getAllStickersReq = async () => {
  try {
    const response = await axiosInstance.get(`/sticker/get-all-stickers`);
    return response.data;
  } catch (error) {
    console.error("Error creating sticker:", error.message);
    throw error;
  }
};

export const deleteStickersReq = async (stickerId) => {
  try {
    const response = await axiosInstance.delete(
      `/sticker/delete-sticker/${stickerId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating sticker:", error.message);
    throw error;
  }
};
