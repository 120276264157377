import axiosInstance from "../../api/AxiosInstance";

export const darsboardReq = async () => {
  try {
    const response = await axiosInstance.get(`/admin/admin-dashboard`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};
