import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getUserProfileRequest,
  deleteUserReq,
} from "../__requests/UserRequests";
import { getUserCoinRequest } from "../__requests/CoinRequests";
import { MdModeEditOutline } from "react-icons/md";
import EditCoinModal from "./EditCoinModal";
import { toast } from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { parsePhoneNumber } from "libphonenumber-js";
import DeleteUserModal from "./DeleteUserModal";
import UserPosts from "./UserPosts";

// Initialize the countries library
countries.registerLocale(enLocale);

const ProfileDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState("");
  const [activeTab, setActiveTab] = useState("about");

  const getUserProfile = async () => {
    const response = await getUserProfileRequest(id);
    setUserProfile(response);
  };

  useEffect(() => {
    if (id) {
      getUserProfile();
    }
  }, [id]);

  const [userCoin, setUserCoin] = useState("");

  const getUserCoin = async () => {
    try {
      const response = await getUserCoinRequest(id);
      setUserCoin(response?.coin || null);
    } catch (error) {
      console.error("Error", error);
      setUserCoin(null);
    }
  };
  useEffect(() => {
    if (id) {
      getUserCoin();
    }
  }, [id, userProfile]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleUpdateSuccess = () => {
    getUserCoin(); // Refresh coin data
  };

  const handleDeleteUser = () => {
    setShowDeleteModal(true);
    setDeleteConfirmText("");
  };

  const confirmDelete = async () => {
    if (deleteConfirmText !== "Delete") {
      toast.error('Please type "Delete" (with capital D) to confirm');
      return;
    }

    try {
      await deleteUserReq(id);
      toast.success("User deleted successfully");
      setShowDeleteModal(false);
      navigate("/users"); // Redirect to users list
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  };

  const getCountryName = (countryCode) => {
    if (!countryCode) return "";
    try {
      return countries.getName(countryCode, "en") || countryCode;
    } catch (error) {
      return countryCode;
    }
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    try {
      const phoneWithCode = phone.startsWith("+") ? phone : `+${phone}`;
      const phoneNumber = parsePhoneNumber(phoneWithCode);
      return phoneNumber.formatInternational();
    } catch (error) {
      console.error("Error formatting phone number:", error);
      return phone;
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
          <img
            src={
              userProfile?.coverPicture
                ? `${process.env.REACT_APP_IMAGE_URL}/${userProfile?.coverPicture}`
                : "/empty_gray_image.jpg"
            }
            alt=""
            className="w-100 object-fit-cover"
            style={{ height: "250px" }}
          />
          <div className="pb-24 ms-16 mb-24 me-16  mt--100">
            <div className="text-center border border-top-0 border-start-0 border-end-0">
              <img
                src={
                  userProfile?.profilePicture
                    ? userProfile?.profilePicture?.includes(
                        "https://lh3.googleusercontent"
                      )
                      ? userProfile?.profilePicture
                      : `${process.env.REACT_APP_IMAGE_URL}/${userProfile?.profilePicture}`
                    : "/Default_user_Image.png"
                }
                alt=""
                className="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover"
              />
              <h6 className="mb-0 mt-16">
                {userProfile?.first_name} {userProfile?.last_name}
              </h6>
              <span className="text-secondary-light mb-16">
                {userProfile?.email}
              </span>
              <div className="d-flex justify-content-center gap-2 mt-3 mb-3">
                <button
                  className={`btn ${
                    activeTab === "about"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setActiveTab("about")}
                >
                  About User
                </button>
                <button
                  className={`btn ${
                    activeTab === "posts"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setActiveTab("posts")}
                >
                  User Posts
                </button>
              </div>
            </div>
            {activeTab === "about" ? (
              <>
                {userProfile &&
                  (userProfile.phone ||
                    userProfile.address_country ||
                    userProfile.address_city ||
                    userProfile.dob ||
                    userProfile.about) && (
                    <div className="mt-24">
                      <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                        <span className="text-xl fw-bold fs-4 text-primary">
                          Personal Info
                        </span>
                      </div>
                      <ul>
                        {userProfile?.phone && (
                          <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                              {" "}
                              Phone Number
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                              : {formatPhoneNumber(userProfile?.phone)}
                            </span>
                          </li>
                        )}
                        {userProfile?.address_country && (
                          <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                              {" "}
                              Country
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                              : {getCountryName(userProfile?.address_country)}
                            </span>
                          </li>
                        )}
                        {userProfile?.address_city && (
                          <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                              {" "}
                              City
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                              : {userProfile?.address_city}
                            </span>
                          </li>
                        )}
                        {userProfile?.dob && (
                          <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                              {" "}
                              Date of Birth
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                              : {formatDate(userProfile?.dob)}
                            </span>
                          </li>
                        )}
                        {userProfile?.about && (
                          <li className="d-flex align-items-center gap-1">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                              {" "}
                              About
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                              : {userProfile?.about}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                <div className="mt-24">
                  <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                    <span className="text-xl fw-bold fs-4 text-primary">
                      FruityChat Info
                    </span>
                    <button
                      className="btn btn-danger"
                      onClick={handleDeleteUser}
                    >
                      Delete User
                    </button>
                  </div>
                  <ul>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Joining Date
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {formatDate(userProfile?.createdAt)}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Total Posts
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {userProfile?.totalPosts || 0}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Profile Views
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {userProfile?.totalProfileViews || 0}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-24">
                  <div className="d-flex align-items-center justify-content-between border rounded-16 p-4 bg-primary-subtle rounded-3 mb-16">
                    <span className="text-xl fw-bold fs-4 text-primary">
                      Fruity Coin Info
                    </span>
                    <button
                      className="btn btn-primary d-flex align-items-center justify-content-center"
                      onClick={handleEditClick}
                    >
                      <MdModeEditOutline /> Edit Coins
                    </button>
                  </div>
                  <ul>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Total Coins
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {userCoin?.totalCoins ?? "0"}
                      </span>
                    </li>
                    <li className="d-flex align-items-center gap-1 mb-12">
                      <span className="w-30 text-md fw-semibold text-primary-light">
                        Remaining Energies
                      </span>
                      <span className="w-70 text-secondary-light fw-medium">
                        : {userCoin?.energies ?? "0"}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <UserPosts id={id} />
            )}
          </div>
        </div>
      </div>
      <DeleteUserModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        deleteConfirmText={deleteConfirmText}
        setDeleteConfirmText={setDeleteConfirmText}
        onConfirm={confirmDelete}
      />
      <EditCoinModal
        show={showEditModal}
        onClose={handleCloseModal}
        userId={id}
        currentCoins={userCoin?.totalCoins}
        currentEnergy={userCoin?.energies}
        onUpdate={handleUpdateSuccess}
      />
      <DeleteUserModal />
    </>
  );
};

export default ProfileDetail;
