import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import ReferralTable from "../components/Referrals/ReferralTable";

const ReferralList = () => {
  return (
    <MasterLayout>
      <ReferralTable />
    </MasterLayout>
  );
};

export default ReferralList;
