import React, { useState, useEffect } from "react";
import { getCompaniestReq } from "../__requests/CompanyRequests";
import DataTable from "react-data-table-component";
import { ScaleLoader } from "react-spinners";
import { parsePhoneNumber } from "libphonenumber-js";
import Select from "react-select";
import { getSearchFilterReq } from "../__requests/SearchRequest";
import { customList } from "country-codes-list";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";

const countryNameList = customList("countryCode", "{countryNameEn}");

const formatPhoneNumber = (phone) => {
  if (!phone) return "";
  try {
    const phoneWithCode = phone.startsWith("+") ? phone : `+${phone}`;
    const phoneNumber = parsePhoneNumber(phoneWithCode);
    return phoneNumber.formatInternational();
  } catch (error) {
    console.error("Error formatting phone number:", error);
    return phone;
  }
};

const AllCompaniesList = () => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const countryOptions = Object.entries(countryNameList).map(
    ([code, name]) => ({
      value: code,
      label: name,
    })
  );

  const handleSearch = async (text, country) => {
    setLoading(true);
    try {
      // If both filters are empty, fetch all companies
      if (!text && !country) {
        await getAllCompanies(currentPage, perPage);
        return;
      }

      // Search by text
      if (text) {
        const textResponse = await getSearchFilterReq(text, false);
        setAllCompanies(textResponse?.socialPages || []);
        setTotalRows(textResponse?.socialPages?.length || 0);
      }

      // Search by country
      if (country) {
        const countryResponse = await getSearchFilterReq(country.value, true);
        setAllCompanies(countryResponse?.socialPages || []);
        setTotalRows(countryResponse?.socialPages?.length || 0);
      }
    } catch (error) {
      console.error("Error searching companies:", error);
    }
    setLoading(false);
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setFilterText(text);

    // Debounce search
    if (searchTimeout) clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        handleSearch(text, selectedCountry);
      }, 500)
    );
  };

  const handleCountryChange = (selected) => {
    setSelectedCountry(selected);
    if (!selected && !filterText) {
      // If both filters are cleared, fetch all companies
      getAllCompanies(currentPage, perPage);
    } else {
      handleSearch(filterText, selected);
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Company Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Owner",
      selector: (row) => row.userData?.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => formatPhoneNumber(row.contact_number),
    },
    {
      name: "Address",
      selector: (row) => row.address,
      wrap: true,
    },
    {
      name: "Profile",
      cell: (row) => (
        <Link to={`/company-profile/${row?._id}`}>
          <Button
            className="btn btn-primary btn-sm p-1 d-flex align-items-center justify-content-center"
            disabled={!row?._id}
          >
            <FaUserAlt />
          </Button>
        </Link>
      ),
      width: "100px",
      sortable: false,
    },
  ];

  const subHeaderComponent = (
    <div className="d-flex align-items-center justify-content-evenly w-100 gap-2">
      <input
        type="text"
        className="form-control w-50"
        placeholder="Search by company name..."
        value={filterText}
        onChange={handleTextChange}
      />
      <Select
        isClearable
        placeholder="Select Country"
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
        className="w-50"
      />
    </div>
  );

  const getAllCompanies = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getCompaniestReq(page, limit);
      setAllCompanies(response?.pages || []);
      setTotalRows(response?.totalDocs || 0);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Only fetch all companies if no search is active
    if (!filterText && !selectedCountry) {
      getAllCompanies(currentPage, perPage);
    }
  }, [currentPage, perPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="card">
      <div className="card-body">
        <DataTable
          title="All Companies"
          columns={columns}
          data={allCompanies}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          progressPending={loading}
          progressComponent={
            <div className="p-4 w-100">
              <div className="d-flex justify-content-center">
                <ScaleLoader color="#0d6efd" />
              </div>
            </div>
          }
          subHeader
          subHeaderComponent={subHeaderComponent}
          responsive
          striped
          highlightOnHover
          persistTableHead
        />
      </div>
    </div>
  );
};

export default AllCompaniesList;
