import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import ProfileDetail from '../components/Users-Data/ProfileDetail'

const UserProfile = () => {
  return (
    <MasterLayout>
      <ProfileDetail />
    </MasterLayout>
  )
}

export default UserProfile
