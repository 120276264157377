import { Icon } from "@iconify/react/dist/iconify.js";
import { IoBriefcase } from "react-icons/io5";
import { PiBuildingApartmentFill } from "react-icons/pi";
import React from "react";
import { TiShoppingCart } from "react-icons/ti";
import { BsFilePost } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Dashboard.css";

const FruityDetailCards = ({ dashboardCount }) => {
  return (
    <div className="row row-cols-xxxl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-4">
      <div className="col">
        <Link
          to="/all-users"
          className="card shadow-none border bg-gradient-start-1 h-100 fruity-card"
        >
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">Total Users</p>
                <h6 className="mb-0">{dashboardCount?.users || 0}</h6>
              </div>
              <div className="w-50-px h-50-px bg-cyan rounded-circle d-flex justify-content-center align-items-center">
                <Icon
                  icon="gridicons:multiple-users"
                  className="text-white text-2xl mb-0"
                />
              </div>
            </div>
          </div>
        </Link>
        {/* card end */}
      </div>
      <div className="col">
        <Link
          to="/Companies"
          className="card shadow-none border bg-gradient-start-2 h-100 fruity-card"
        >
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">
                  Total Companies
                </p>
                <h6 className="mb-0">{dashboardCount?.companyPages || 0}</h6>
              </div>
              <div className="w-50-px h-50-px bg-purple rounded-circle d-flex justify-content-center align-items-center">
                <PiBuildingApartmentFill className="text-white text-2xl mb-0" />
              </div>
            </div>
          </div>
        </Link>
        {/* card end */}
      </div>
      <div className="col">
        <div className="card shadow-none border bg-gradient-start-3 h-100 fruity-card">
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">Total Posts</p>
                <h6 className="mb-0">{dashboardCount?.posts || 0}</h6>
              </div>
              <div className="w-50-px h-50-px bg-info rounded-circle d-flex justify-content-center align-items-center">
                <BsFilePost className="text-white text-2xl mb-0" />
              </div>
            </div>
          </div>
        </div>
        {/* card end */}
      </div>
      <div className="col">
        <div className="card shadow-none border bg-gradient-start-4 h-100 fruity-card">
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">Total Jobs</p>
                <h6 className="mb-0">{dashboardCount?.jobs || 0}</h6>
              </div>
              <div className="w-50-px h-50-px bg-success-main rounded-circle d-flex justify-content-center align-items-center">
                <IoBriefcase className="text-white text-2xl mb-0" />
              </div>
            </div>
          </div>
        </div>
        {/* card end */}
      </div>
      <div className="col">
        <div className="card shadow-none border bg-gradient-start-5 h-100 fruity-card">
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">
                  Total Products
                </p>
                <h6 className="mb-0">{dashboardCount?.marketProducts || 0}</h6>
              </div>
              <div className="w-50-px h-50-px bg-red rounded-circle d-flex justify-content-center align-items-center">
                <TiShoppingCart className="text-white text-2xl mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-none border bg-gradient-start-1 h-100 fruity-card">
          <div className="card-body p-20">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div>
                <p className="fw-medium text-primary-light mb-1">
                  Total Subscription
                </p>
                <h6 className="mb-0">0</h6>
              </div>
              <div className="w-50-px h-50-px bg-cyan rounded-circle d-flex justify-content-center align-items-center">
                <Icon
                  icon="fa-solid:award"
                  className="text-white text-2xl mb-0"
                />
              </div>
            </div>
          </div>
        </div>
        {/* card end */}
      </div>
    </div>
  );
};

export default FruityDetailCards;
