import axiosInstance from "../../api/AxiosInstance";

export const getAllJobsReq = async (page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/social/page/jobs?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching jobs data", error.message);
    return { data: null, error: "Error in fetching jobs data" };
  }
};
