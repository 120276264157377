import axiosInstance from "../../api/AxiosInstance";

export const getUserToConnectReq = async (page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/fruit/user/social/allusers/admin?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const getUserProfileRequest = async (userId) => {
  try {
    const response = await axiosInstance.get(`/fruit/user/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const UsersByCountry = async () => {
  try {
    const response = await axiosInstance.get(`/fruit/user/social/users/country`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const deleteUserReq = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/fruit/user/accout-delete/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};
