import axiosInstance from "../../api/AxiosInstance";

export const getCompaniestReq = async (page, limit) => {
  try {
    const response = await axiosInstance.get(
      `/social/page/admin?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching users data", error.message);
    return { data: null, error: "Error fetch users for chat" };
  }
};

export const getsingleCompanyReq = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/social/page/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error in fetching company profile", error.message);
    return { data: null, error: "Error fetch company profile" };
  }
};

export const getCompanyDetailsReq = async (companyId) => {
  try {
    const response = await axiosInstance.get(
      `/account-details/company/${companyId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching company profile", error.message);
    return { data: null, error: "Error fetch company profile" };
  }
};
